<template>
  <section>
    <div class="cols">
      <img
        src="@/assets/OJB2.png"
        alt="Logo"
        @click="$router.push('/')"
      />
    </div>
    <div class="cols">
      <a
        class="text-dark a"
        href="https://goo.gl/maps/nDQFtVSBtd2VMaQS7"
        target="_blank"
        ><address>
          Wilsonstraat 127 <br />
          2131PN Hoofddorp
        </address></a
      >
      <a
        class="text-dark"
        href="https://goo.gl/maps/nDQFtVSBtd2VMaQS7"
        target="_blank"
        ><FontAwesomeIcon
          icon="fa-solid fa-location-dot"
          size="4x"
      /></a>
    </div>
    <div class="cols">
      <h1 class='mb-1'>Openingstijden</h1>
      <div class="openings">
        <p>Maandag: <strong>09:00-18:00</strong></p>
        <p>Dinsdag: <strong>09:00-18:00</strong></p>
        <p>Woensdag: <strong>09:00-18:00</strong></p>
        <p>Donderdag: <strong>09:00-18:00</strong></p>
        <p>Vrijdag: <strong>09:00-18:00</strong></p>
        <p>Zaterdag: <strong>09:00-18:00</strong></p>
        <p>Zondag: <strong>Gesloten</strong></p>
      </div>
      <FontAwesomeIcon
        icon="fa-solid fa-clock"
        size="4x"
      />
    </div>
  </section>
  <br />
  <hr />
  <Footer />
</template>

<script>
export default {
  name: 'Details',
  created() {},
  data() {
    return {}
  },
  props: {},
  methods: {},
}
</script>

<style
  lang="scss"
  scoped
>
section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $light;
  padding: 1rem 0;
  margin-bottom: -.8rem;

  img {
    width: 180px;
    height: auto;
  }

  address {
    font-size: 1.4rem;
  }

  .cols {
    margin: 1rem 0;
  }

  .openings {
    font-size: 1rem;
    text-align: left;
    width: 100%;
    display: block;
    justify-items: center;
    justify-content: space-between;

    strong {
      float: right;
      margin-left: 1rem;
    }
  }

  .a {
    text-decoration: none;
  }

  @include tabS {
    flex-direction: row;
    justify-content: space-evenly;
  }
}
</style>
