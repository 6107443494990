<template>
	<div class="jumbo">
		<transition name="fadeIN">
			<Vido
				class="vid"
				v-if="swich"
			/>
		</transition>

		<transition name="fadeOUT">
			<Show v-if="!swich" />
		</transition>
	</div>
</template>

<script>
	// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
	import Vido from "./Vido.vue";
	import Show from "./Show.vue";
	export default {
		data() {
			return {
				swich: false,
				show: false,
			};
		},
		components: { /* FontAwesomeIcon */ Vido, Show },
		methods: {
			makeSwich() {
				setInterval(() => {
					this.swich = !this.swich;
				}, 37000);
			},
		},

		created() {
			this.makeSwich();
		},
	};
</script>

<style
	lang="scss"
	scoped
>
	.jumbo {
		width: 100%;
		min-height: 80vh;
		background-image: url(../assets/images/jumbo.jpg);
		width: 100%;
		height: fit-content;
		background-position: center;
		background-size: cover;
		top: -5.6rem;
		position: relative;
		z-index: 9;
		justify-content: center;
		align-items: center;
		display: flex;
		flex-direction: column;
		margin-bottom: -5.6rem !important;
		background-attachment: fixed;

		@include labM {
			top: -7.4rem;
			min-height: 90vh;

			h1 {
				margin-bottom: 0;
				font-size: $xxl-font * 2;
			}
		}
	}

	.vid {
		margin-top: 6rem;
	}

	.fadeIN-enter-active,
	.fadeIN-leave-active,
	.fadeOUT-enter-active,
	.fadeOUT-leave-active {
		opacity: 1;
		transition: opacity 0.8s ease;
	}

	.fadeIN-enter-from,
	.fadeIN-leave-to,
	.fadeOUT-enter-from,
	.fadeOUT-leave-to {
		opacity: 0;
	}
</style>
