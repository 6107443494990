<template>
  <Navbar />

  <main class="view">
    <router-view />
  </main>

</template>

<style lang="scss">
hr {
  border: none;
  padding: 1.5px;
}
</style>
