import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ContactView from '../views/ContactView.vue'
// import ProjectenSection from '@/components/sections/ProjectenSection'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Home',
    },
  },
  {
    path: '/contact',
    name: 'contact',
    hash: 'contact',
    component: ContactView,
    meta: {
      title: 'Contact',
    },
  },
  {
    path: '/diensten',
    name: 'diensten',
    hash: 'diensten',
    // component: ProjectenSection,
    meta: {
      title: 'Over ons',
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active-link',
  linkExactActiveClass: 'exact-active-link',
  scrollBehavior(to, from, savedPosition) {
    document
      .getElementById('app')
      .scrollIntoView({ behavior: 'smooth', left: 0, top: 0 })
    if (savedPosition) {
      return savedPosition
    }
    // const pos = {}
    if (to.hash) {
      // if (document.querySelector(to.hash)) {
      //   return pos
      // }
      return { el: to.hash, top: 90 }
    }
    return { left: 0, top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  const docT = `${to.meta.title} | OJB `
  document.title = docT
  next()
})

export default router
