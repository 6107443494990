<template>
  <div
    class="section"
    id="brand"
  >
    <img
      src="@/assets/ikon/building-regular.svg"
      alt="Bouw"
      class="ikon"
    />
    <img
      src="@/assets/ikon/house-chimney-solid.svg"
      alt="Huis"
      class="ikon"
    />
    <img
      src="@/assets/ikon/person-digging-solid.svg"
      alt="Person"
      class="ikon"
    />

    <!--     mask="fa-solid fa-circle" 
    mask="fa-solid fa-circle" 
    mask="fa-solid fa-circle"  -->
    <!-- <h1>Branden</h1> -->
    <!-- <FontAwesomeIcon
        icon="fa-solid fa-building"
        class="mx-4"
        size="6x"
      />
      <FontAwesomeIcon
        icon="fa-solid fa-house-chimney"
        class="mx-4"
        size="6x"
      />
      <FontAwesomeIcon
        icon="fa-solid fa-person-digging"
        class="mx-4"
        size="6x"
      /> -->
  </div>
</template>

<script>
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'BrandSection',
  created() {},
  data() {
    return {}
  },
  props: {},
  methods: {},
  // components: { FontAwesomeIcon },
}
</script>

<style
  lang="scss"
  scoped
>
#brand {
  padding: 2rem 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
}
.ikon {
  width: 35%;
  height: 35%;
}
</style>
