<!-- eslint-disable vue/no-parsing-error -->
<template>
  <header
    class="navbar"
    :class="{ scrolDown: scrolShod }"
  >
    <!-- Logo -->
    <Logo />

    <!-- Menu Bars Icon -->
    <Transition
      name="menuIcon"
      mode="out-in"
    >
      <div
        v-if="mobile"
        class="z-1"
        @click.prevent="toggleMobilNav"
      >
        <span v-if="!icon">
          <i class="fa fa-bars fa-3x text-primary"></i>
        </span>
        <span v-else>
          <i class="fa fa-times fa-3x text-primary"></i>
        </span>
      </div>
    </Transition>

    <!-- Compo of MobileMenu -->
    <MobileMenu v-if="mobileNav" />

    <!-- Compo of DeskMenu -->
    <DeskMenu v-if="!mobile" />
  </header>
</template>

<script>
import Logo from './Logo'
import DeskMenu from './DeskNav'
import MobileMenu from './MobileNav'
export default {
  data() {
    return {
      icon: null,
      iconOpen: null,
      mobileNav: null,
      mobile: null,
      open: null,
      scrolShod: null,
      windowWidth: null, // bepalen wat het innerWidth is
      iconState: null,
      isShowing: null,
    }
  },
  components: { Logo, DeskMenu, MobileMenu },
  created() {
    // Call function voor screen
    window.addEventListener('resize', this.checkScreen())
    this.checkScreen()
  },
  mounted() {
    window.addEventListener('scroll', this.updScrol)
  },

  // Watch
  watch: {
    $route() {
      this.mobileNav = null
      this.icon = null
    },
  },

  methods: {
    checkScreen() {
      this.windowWidth = window.innerWidth
      if (this.windowWidth <= 568) {
        this.mobile = true
        this.iconOpen = true
        // this.mobileNav = true
        return
      }
      this.mobile = false
      // this.mobileNav = false
      this.iconOpen = false

      return
    },

    // Toggle function
    toggleMobilNav() {
      this.mobileNav = !this.mobileNav

      if (this.mobileNav) {
        this.icon = true
        this.iconState = true
      } else {
        this.icon = false
        this.iconState = false
      }
    },

    updScrol() {
      const scrolPos = window.scrollY
      if (scrolPos > 50) {
        this.scrolShod = true
      } else {
        this.scrolShod = false
      }
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
div {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  z-index: 9999 !important;
}

.menuIcon-enter-active,
.menuIcon-leave-active {
  /* opacity: 1; */
  transition: all 0.5s ease-out;
}

.menuIcon-enter-from {
  opacity: 0;
  transform: translateY(30px);
}
.menuIcon-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

/* .icon-enter-to {
  transform: translateX(0);
} */
</style>
