<template>
  <nav id="nav">
    <router-link
      v-for="item in menuItems"
      :key="item.id"
      :to="`#${item.id}`"
      @click.stop
    >
      {{ item.naam }}
    </router-link>
  </nav>
</template>

<script>
export default {
  name: 'DeskNav',
  created() {},
  data() {
    return {}
  },
  props: {},
  methods: {},
}
</script>

<style
  lang="scss"
  scoped
></style>
