<template>
  <!-- <span class="text-derde">Welkom bij <br> Omi Jan Bouw</span> -->
  <div
    class="home"
    id="home"
  >
    <Jumbo />

    <OverOnsSection />

    <DienstenSection />

    <BrandSection />

    <ContactSection />
  </div>
  <Details />
</template>

<script>
import DienstenSection from '@/components/sections/DienstenSection.vue'
import OverOnsSection from '@/components/sections/OverOnsSection.vue'
import ContactSection from '@/components/sections/ContactSection.vue'
import BrandSection from '@/components/sections/BrandSection.vue'
import Jumbo from '@/components/Jumbo.vue'
import Details from '@/components/overal/Details.vue'

export default {
  name: 'HomeView',
  components: {
    OverOnsSection,
    ContactSection,
    BrandSection,
    Jumbo,
    Details,
    DienstenSection,
  },
}
</script>

<style
  lang="scss"
  scoped
>
.home {
  width: 100%;
  height: auto;
  position: relative;
  display: block;
}
</style>
