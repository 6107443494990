<template>
  <router-link
    to="/"
    id="logo"
  >
    <img
      src="@/assets/OJB2.png"
      alt="Logo is not loaded"
      class="img"
      :class="scrolDown"
    />
  </router-link>
</template>

<script>
export default {}
</script>

<style
  lang="scss"
  scoped
>
#logo {
  z-index: 99;
  width: 100%;
  height: 100%;
  margin-top: -0.3rem;
  animation: rot 1.5s linear 2s;
  
  img {
    @include mobS {
      width: 60px;
      height: 70px;
    }
    @include mobM {
      width: 120px;
      height: 90px;
    }
    @include mobL {
      width: 130px;
      height: 60px;
    }
    @include tabS {
      width: 150px;
      height: 90px;
    }
    @include labS {
      width: 180px;
      height: 90px;
    }
    @include labM {
      width: 220px;
      height: 150px;
    }
    @include labL {
      width: 300px;
      height: 250px;
    }
    @include tv {
      width: 450px;
      height: 300px;
    }
  }
}

.scrolDown {
  position: fixed;
  top: 0;
  background-color: lighten($second, 2%);
  z-index: 9999;
  transition: all 0.8s ease-in-out;

  img {
    padding-top: 0.5rem;
    transition: all 0.9s ease-in;
    @include mobM {
      width: 65px !important;
      max-height: 55px;
      transition: width 0.9s ease-out;
    }

    @include labM {
      width: 120px !important;
      max-height: 90px;
    }
  }

  @include mobS {
    height: 80px;
  }

  @include labM {
    height: 120px;
  }

  #nav a {
    color: $light;
  }
}

/* Animation */
@keyframes rot {

  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
</style>
