<template>
  <div class="section p-3" id="diensten">
    <h1>Onze diensten</h1>

    <section class="girid" v-for="(pr, ind) in diensten" :key="ind">
      <div class="p-2 pro-links">

        <img :src="getImgSrc(pr.fotos[0])" :alt="`${pr.fotos[0]} is niet geladen.`" class="projectIMG" />
<!-- v-for='fot in pr.fotos' :key='fot' :src="getImgSrc(fot) -->
        <!-- <Carousel>
            <Slide v-for="slid in pr.fotos" :key="slid">
              <img :src="getImgSrc(slid)" :alt="`${slid} is niet geladen.`" class="projectIMG" />
             
            </Slide>
         

          <template #addons>
            <Navigation />
            <Pagination />
          </template>
        </Carousel> -->

      </div>

      <div class="pro-rechts">
        <h1>{{ pr.naam }}</h1>
        <p class="lead">
          {{ pr.details }}
          <br />
          <br>
        <ul v-if="pr.lijst">
          <li v-for='(li, ind) in pr.lijst' :key='ind' class='ta-l'>{{ li }}</li>
        </ul>
        </p>
      </div>
    </section>
  </div>
</template>

<script>
// import 'vue3-carousel/dist/carousel.css'
// import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
export default {
  name: 'Diensten',
  created () { },
  data ()
  {
    return {}
  },
  props: {},
  methods: {},
  components: {
    // Carousel,
    // Slide,
    // Pagination,
    // Navigation,
  }
}
</script>

<style lang="scss" scoped>
.projectIMG {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  display: flex;
  margin: 0 auto;
}

.lead {
  text-align: left !important;
  color: $dark;
}

.girid {
  display: grid;
  height: auto;
  padding: 2rem 0;

  @include labS {
    grid-template-columns: 1.5fr 1fr;
  }

  .pro-links {
    padding: 1rem;
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
  }

  .pro-rechts {
    padding: 1rem;
    // border: 1px solid black;
    text-align: left;

    h1{font-size: 1rem;} 


    @include labS {
      display: flex;
      flex-direction: column;
      align-self: center;

      h1{font-size: 1.5rem;}
    }
  }
}
</style>
