const projecten = [
  {
    id: 1,
    naam: 'Timmerwerk',
    fotos: ['build4', 'build1'],
    details:
      'Timmerwerk is een breed begrip; hierom gaat het over het vervangen, vernieuwen of renoveren van houten elementen in en rondom je huis. Je kunt een timmerman dus inschakelen voor zowel buitenwerk als binnenwerk. Dit kan van alles zijn:',
    lijst: [
      'Kleding- of boekenkasten op maat, nieuwe kozijnen of zelfs het bouwen van een schuur',
      'Buitendeuren en Poort',
      'Kozijnen en ramen',
      'Binnendeuren en deurlijsten',
      'Kasten op maat, meubels en ombouw',
      'Dakkapel en dakvenster',
      'Wanden en muren',
      "Veranda's, schuur en overkappingen",
      'Verlaagd plafond',
      'Trap',
    ],
  },
  {
    id: 2,
    naam: 'Loodgieterswerk',
    fotos: ['build14', 'build4'],
    details:
      'Hieronder valt het aanleggen en onderhouden van verwarmingsinstallaties, sanitair,  cv ketel, waterleidingen en riolering dit is vakwerk. Onze loodgieters verstaan de kunst van dit vak. Zowel het aansluiten en repareren van leidingen doen zij veilig en precies.',
  },
  {
    id: 3,
    naam: 'Stuken',
    fotos: ['build6', 'build6'],
    details:
      'Mooie strakke wanden en plafonds geven natuurlijk een super mooie uitstraling aan uw bestaande of nieuwbouw woning. Wij adviseren u graag en nemen graag de stucwerkzaamheden op ons. Ons team aan specialisten gaat graag bij u aan het werk.Mooi glad stucwerk kan een huis geheel afmaken zodat uw muren en plafonds hierna geschilderd of gespoten kunnen worden met latexverf.',
  },
  {
    id: 4,
    naam: 'Schilderwerk',
    fotos: ['build16', 'build8'],
    details:
      'De werkzaamheden van een schilder zijn uiteenlopend, maar over het algemeen is een schilder te omschrijven als iemand die houtwerk, kunststof, beton en vele andere materialen van verf voorziet. Verder is niet alleen schilderen een van de werkzaamheden, maar behoren ook onderhoud, renovatie en reparatie tot de werkzaamheden van een schilder. Wij kunnen u hierin volledig voorzien.Er zijn verschillende werkzaamheden die wij uitvoeren. Een aantal van die werkzaamheden hebben we hieronder voor u op een rijtje gezet:',

    lijst: [
      'Uitvoeren van schilderwerk, onderhoud en/ of renovatie binnen en buiten',
      'Schilderen van losse objecten zoals bijvoorbeeld kasten en deuren',
      'Werkmateriaal leveren zoals trappen, steigers, schuren, plamuren en krabben van ondergronden ramen en kozijnen',
    ],
  },
  {
    id: 5,
    naam: 'Elektra -en data-aansluitingen kantoorpand',
    fotos: ['build17', 'build10'],
    details:
      'De aanleg van een veilige en stabiele elektra -en data-aansluiting is een belangrijk punt.Een kantoorpand of woning zonder elektra -en data-aansluiting is ondenkbaar. Van groepenkast tot wandcontactdoos en van wall outlet tot patchpanelen. Niks is ons te gek! Wij realiseren uw wensen en denken graag met u mee.',
  },
  {
    id: 6,
    naam: 'Uitbouw',
    fotos: ['build7', 'build9'],
    details:
      'Voor het realiseren van uw droomuitbouw bent u bij ons aan het juiste adres. Wilt u een klassieke aanbouw of toch strak en modern, we hebben ruime ervaring in het plaatsen van uitbouwen, opbouwen en serres. Vaak worden wij betrokken vanaf het eerste idee en kunnen zo onze kennis en kunde inbrengen in het bouwproces. Vraag informatie aan.',
  },
  {
    id: 7,
    naam: 'Verbouwen',
    fotos: ['build1', 'build9'],
    details:
      'Heeft u plannen om te gaan verbouwen? De gedroomde uitbouw met veranda aan uw woonhuis, het herindelen van een etage of toch kiezen voor extra ruimte? Wellicht denkt u eraan om uw kantoor te verbouwen of uw bedrijfsrestaurant een facelift te geven. Allemaal zaken die betrekking hebben op bouwen en het laten uitvoeren van diverse bouwkundige werkzaamheden.',
  },
]

export default projecten
