<template>
  <p
    v-if="gestuurd"
    class="bg-second text-primary p-4 font-lg"
  >
    Bedankt voor uw bericht
  </p>
  <h1 class="mt-4">Contact</h1>
  <p class='lead'>U kunt altijd een bericht sturen</p>
  <div
    class="contact"
    id="contact"
  >
    <form
      class="form"
      @submit.prevent="addBericht"
    >
      <input
        type="text"
        name="naam"
        placeholder="Vul uw naam in ..."
        v-model="naam"
        required
      />
      <input
        type="email"
        name="email"
        placeholder="Vul uw email in ..."
        v-model="email"
        required
      />
      <textarea
        rows="12"
        placeholder="Uw bericht"
        v-model="bericht"
      ></textarea>

      <button
        type="submit"
        class="btn bg-second text-light p-2"
      >
        Verzenden
      </button>

      <div v-if="errors.length > 0">
        {{ errors }}
      </div>
    </form>

    <iframe
      src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d78066.34468734806!2d4.612238970431138!3d52.30559928778138!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m5!1s0x47c5e74e6f33f433%3A0xb396673d0e4b5730!2sOmi%20Jan%20Bouw!3m2!1d52.305584599999996!2d4.6822851!4m0!5e0!3m2!1snl!2snl!4v1674745839893!5m2!1snl!2snl"
      style="border: 0"
      allowfullscreen="true"
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
      class="iframe"
    ></iframe>
  </div>
</template>

<script>
import { db } from '@/data/ProjectService'
import { collection, addDoc } from 'firebase/firestore'
export default {
  name: 'Contact',
  created() {},
  data() {
    return {
      naam: '',
      email: '',
      bericht: '',
      gestuurd: null,
      errors: [],
    }
  },
  props: {},
  methods: {
    async addBericht() {
      try {
        const refBericht = await addDoc(collection(db, 'berichten'), {
          naam: this.naam,
          email: this.email,
          bericht: this.bericht,
          createdAt: new Date(),
        })
        refBericht

        if (this.naam === '') {
          alert('Vul een geldig naam')
        }

        // console.log(refBericht.id)
        this.gestuurd = true

        // Een vertraging maken
        setTimeout(() => {
          this.$router.push('/')
          scroll({ behavior: 'smooth' })
          this.gestuurd = false
          this.naam = ''
          this.email = ''
          this.bericht = ''
          // this.$router.resolve( '/' )
        }, 4000)
      } catch (err) {
        this.errors = err
      }

      // const obj = { naam: this.naam, email: this.email, bericht: this.bericht }
      // console.log('OK', obj.id)
      // return addDoc(collection(db, 'berichten'), obj)
    },
  },
}
</script>

<style lang="scss">
.contact {
  display: block;
  width: 400px;
  margin: 0 auto;
  padding: 1rem;

  @include tabS {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 1fr;
    padding: 3rem;
    gap: 0 1rem;
  }
}

.form {
  width: 100%;
  height: 100%;
  border: 1px solid $derde;
  padding: 1rem;
  background-color: $light;
  border-radius: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  textarea {
    border: none;
    border-bottom: 1px solid $derde;
    overflow: hidden;
    position: relative;
  }
}

.iframe {
  width: 100%;
  height: 100%;
  border-radius: 1rem;

  // @include tabS {
  //   width: 700px;
  // }

  // @include labM {
  //   min-width: 1400px;
  // }
}
</style>
