<template>
  <nav>
    <router-link
      v-for="item in menuItems"
      :key="item.id"
      :to="`#${item.id}`"
      @click.stop
    >
      {{ item.naam }}
    </router-link>
  </nav>
  <!--       :class="{'router-link-active': this.$router === item.id}" -->
</template>

<script>
export default {
  name: 'MobileNav',
  created() {},
  data() {
    return {}
  },
  props: {},
  methods: {},
}
</script>

<style
  lang="scss"
  scoped
>
nav {
  background-color: lighten($primary, 58%);
  position: absolute;
  z-index: 99;
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 400px;
  border-radius: 10px 0 0 10px;
  padding: 2rem;
  justify-self: right;
  justify-content: center;
  align-items: space-between;
  transition: width 0.7s ease-in-out;
  animation: fromLeft 0.7s linear;

  a {
    font-size: 20px;
    text-decoration: none;
    display: flex;
    color: $second !important;
    margin: 1rem 0;
  }
}

/* :not(.active) {
  color: rgb(53, 53, 182);
  font-weight: bold;
} */
.active-link {
  color: rgb(53, 33, 33);
}
.exact-active-link {
  color: #1c4a36;
  font-weight: bold;
}

.aniReverse {
  animation: fromLeft 0.5s linear reverse;
}

@keyframes fromLeft {
  from {
    right: -200px;
  }
  to {
    right: 0;
  }
}
</style>
