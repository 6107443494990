<template>
	<video
		autoplay
		loop
		muted
		class="mb-3"
	>
		<source
			src="../assets/videos/OJB-Show.mp4"
			type="video/mp4"
		/>
		Video format is not supported by your browser.
	</video>
</template>

<script>
	export default {
		name: "Vid",
		created() {},
		data() {
			return {};
		},
		props: {},
		methods: {},
	};
</script>

<style
	lang="scss"
	scoped
>
	video {
		width: 500px;
		height: 550px;
		display: flex;
	}
</style>
