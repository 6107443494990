<template>
  <div
    class="section"
    id="over"
  >
    <h1>Over ons</h1>
    <p class="lead">
      Omi Jan bouw is een betrouwbaar en professioneel bedrijf, voor een
      complete verbouwing van begin tot eind. Benieuwd hoe wij kunnen helpen bij
      uw klus? We vertellen u graag over de mogelijkheden! Nieuwbouw geeft de
      vrijheid om een project geheel naar wens te maken. Er wordt een ruimte
      gecreëerd die volledig voldoet aan uw eisen, wij verzorgen uw
      nieuwbouwproject van begin tot eind. Onder ons takenpakket valt
      timmerwerk, stuken, schilderwerk, loodgieter, elektra en data
      aansluitingen kantoorpand. Een nieuwbouw wordt een zorgeloos project in
      onze betrouwbare handen. We zijn bekwaam in zowel nieuwbouw als het
      uitvoeren van verbouwingen.
      <br />
      Met onze jarenlange ervaring zijn we dan ook in staat om een gedegen
      advies te geven, toegespitst op uw specifieke situatie.
      <br />
      <br />
      Laat u vrijblijvend adviseren.
    </p>
  </div>
</template>

<script>
export default {
  name: 'OverOns',
  created() {},
  data() {
    return {}
  },
  props: {},
  methods: {},
}
</script>
<style
  lang="scss"
  scoped
>
#over {
  padding: 3rem 0;
}
</style>
