<template>
  <footer class="footer">
    <!-- <hr class='bg-second'/> -->
    <p>Alle rechten voorbehouden door</p>
    <span
      ><router-link
        to="/" 
        class="text-vierde"
        >OJB</router-link
      >&copy;2017-{{ new Date().getFullYear() }}</span
    >
  </footer>
</template>
