import Navbar from '@/components/overal/Navbar.vue'
import Footer from '@/components/overal/Footer.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import diensten from '@/data/projecten'
export default {
  data() {
    return {
      // row:
      //   'col-12-xs col-12-sm col-12-mm col-12-md col-12-lg col-12-xl col-12-xx',
      // col6: 'col-6-xs col-6-sm col-6-mm col-6-md col-6-lg col-6-xl col-6-xx',
      mobile: null,
      isSmalScreen: null,
      diensten: diensten,

      menuItems: [
        {
          naam: 'Home',
          id: 'home',
        },
        {
          naam: 'OverOns',
          id: 'over',
        },
        {
          naam: 'Diensten',
          id: 'diensten',
        },
        {
          naam: 'Gallery',
          id: 'galery',
        },
        {
          naam: 'Contact',
          id: 'contact',
        },
      ],
    }
  },
  components: {
    Navbar,
    Footer,
    FontAwesomeIcon,
  },

  // Live Cycle Hook
  created() {
    // Call function voor screen
    window.addEventListener('resize', this.checkScreen)
    this.checkScreen()
  },
 

  methods: {
    checkScreen() {
      this.windowWidth = window.innerWidth
      if (this.windowWidth <= 480) {
        this.mobile = true
        this.isSmalScreen = true
        return
      }
      this.mobile = false
      this.isSmalScreen = false

      return
    },

    getImgSrc(img) {
      return require(`@/assets/images/${img}.jpg`)
    },
  },
}
