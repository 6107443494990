import { createApp } from 'vue'
import App from './App.vue' 
import './registerServiceWorker'
import router from './router'
import store from './store' 
import Mix from '@/mixins'
import {TransitionRoot} from '@headlessui/vue'

/* import the fontawesome core */
// import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// .component('font-awesome-icon', FontAwesomeIcon)
/* add icons to the library */
// library.add(faUserSecret)


createApp(App)
  .mixin(Mix)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(store)
  .use(router)
  .use(TransitionRoot)
  .mount('#app')
