<template>
	<div>

    <h1 class="text-primary font-xl mt-5">
			Welkom bij <br />
			Omi Jan Bouw
		</h1>


<!-- 		<svg
			id="OBJECTS"
			viewBox="0 0 1246.89 1246.89"
		>
			<defs>
				<clipPath
					id="clip-path"
					transform="translate(318.33 355.79)"
				>
					<polygon
						points="14.19 10.94 14.19 550.69 460.83 654.1 460.83 332.52 640.16 332.52 640.16 10.94 14.19 10.94"
						fill="none"
					/>
				</clipPath>
				<clipPath
					id="clip-path-2"
					transform="translate(318.33 355.79)"
				>
					<rect
						x="459.71"
						y="388.13"
						width="359.78"
						height="265.97"
						fill="none"
					/>
				</clipPath>
			</defs>
			<g id="Plattegrond">
				<polygon
					points="332.53 366.72 332.53 906.48 779.16 1009.89 779.16 688.3 958.49 688.3 958.49 366.72 332.53 366.72"
					fill="#fff"
				/>
				<g clip-path="url(#clip-path)">
					<rect
						x="-465.87"
						y="2.16"
						width="1013.47"
						height="2.5"
						transform="translate(327.89 385.68) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="-448.82"
						y="19.21"
						width="1013.47"
						height="2.5"
						transform="translate(320.83 402.73) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="-431.77"
						y="36.26"
						width="1013.47"
						height="2.5"
						transform="translate(313.77 419.78) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="-397.68"
						y="70.35"
						width="1013.47"
						height="2.5"
						transform="translate(299.65 453.88) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="-363.58"
						y="104.45"
						width="1013.47"
						height="2.5"
						transform="translate(285.52 487.97) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="-329.48"
						y="138.54"
						width="1013.47"
						height="2.5"
						transform="translate(271.4 522.07) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="-295.39"
						y="172.64"
						width="1013.47"
						height="2.5"
						transform="translate(257.28 556.17) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="-244.24"
						y="223.79"
						width="1013.47"
						height="2.5"
						transform="translate(236.09 607.31) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="-278.34"
						y="189.69"
						width="1013.47"
						height="2.5"
						transform="translate(250.21 573.21) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="-227.19"
						y="240.83"
						width="1013.47"
						height="2.5"
						transform="translate(229.03 624.36) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="-193.1"
						y="274.93"
						width="1013.47"
						height="2.5"
						transform="translate(214.91 658.45) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="-141.95"
						y="326.07"
						width="1013.47"
						height="2.5"
						transform="translate(193.72 709.61) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="-90.81"
						y="377.22"
						width="1013.47"
						height="2.5"
						transform="translate(172.54 760.74) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="-56.71"
						y="411.32"
						width="1013.47"
						height="2.5"
						transform="translate(158.41 794.84) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="-22.62"
						y="445.41"
						width="1013.47"
						height="2.5"
						transform="translate(144.29 828.94) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="11.48"
						y="479.51"
						width="1013.47"
						height="2.5"
						transform="translate(130.17 863.03) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="-414.72"
						y="53.3"
						width="1013.47"
						height="2.5"
						transform="translate(306.71 436.83) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="-380.63"
						y="87.4"
						width="1013.47"
						height="2.5"
						transform="translate(292.58 470.92) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="-346.53"
						y="121.5"
						width="1013.47"
						height="2.5"
						transform="translate(278.46 505.02) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="-312.44"
						y="155.59"
						width="1013.47"
						height="2.5"
						transform="translate(264.34 539.12) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="-261.29"
						y="206.74"
						width="1013.47"
						height="2.5"
						transform="translate(243.15 590.27) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="-210.15"
						y="257.88"
						width="1013.47"
						height="2.5"
						transform="translate(221.97 641.41) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="-176.05"
						y="291.98"
						width="1013.47"
						height="2.5"
						transform="translate(207.85 675.5) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="-124.91"
						y="343.12"
						width="1013.47"
						height="2.5"
						transform="translate(186.66 726.65) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="-159"
						y="309.03"
						width="1013.47"
						height="2.5"
						transform="translate(200.78 692.55) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="-107.86"
						y="360.17"
						width="1013.47"
						height="2.5"
						transform="translate(179.6 743.68) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="-73.76"
						y="394.27"
						width="1013.47"
						height="2.5"
						transform="translate(165.48 777.79) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="-39.66"
						y="428.36"
						width="1013.47"
						height="2.5"
						transform="translate(151.35 811.89) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="-5.57"
						y="462.46"
						width="1013.47"
						height="2.5"
						transform="translate(137.23 845.98) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="28.53"
						y="496.56"
						width="1013.47"
						height="2.5"
						transform="translate(123.11 880.08) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="45.58"
						y="513.6"
						width="1013.47"
						height="2.5"
						transform="translate(116.04 897.11) rotate(-45)"
						fill="#bcbdc0"
					/>
					<rect
						x="62.62"
						y="530.65"
						width="1013.47"
						height="2.5"
						transform="translate(108.98 914.18) rotate(-45)"
						fill="#bcbdc0"
					/>
				</g>
				<path
					d="M454.26,660.46,6.51,556.8V3.25H647.84v337H468.51V649.13A11.62,11.62,0,0,1,454.26,660.46ZM21.88,544.58l431.26,99.85V324.83H632.47V18.62H21.88Z"
					transform="translate(318.33 355.79)"
					fill="#e09d00"
				/>
				<path
					d="M454,661.69,6.22,558l-1-1.21V3.25L6.51,2H827.14V4.5H7.76V555.8L454.57,659.25Zm-1.15-16L21.59,545.8l-1-1.22v-526l1.25-1.25H632.47v2.5H23.13V543.59l430.3,99.62Z"
					transform="translate(318.33 355.79)"
				/>
			</g>
			<g id="SlaapKamer">
				<rect
					x="779.16"
					y="688.31"
					width="358.66"
					height="321.58"
					fill="#bcbdc0"
				/>
				<path
					d="M827.13,661.75H453.18V324.87h374Zm-358.65-15.3H811.83V340.17H468.48Z"
					transform="translate(318.33 355.79)"
				/>
				<g clip-path="url(#clip-path-2)">
					<path
						d="M809,412.37a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,809,412.37Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M830.73,416.71a2.17,2.17,0,1,0-2.17-2.17A2.17,2.17,0,0,0,830.73,416.71Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M819.88,423.22a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,819.88,423.22Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M798.18,423.22a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,798.18,423.22Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M819.88,401.52a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,819.88,401.52Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M798.18,401.52a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,798.18,401.52Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M809,455.76a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,809,455.76Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M830.73,455.76a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,830.73,455.76Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M819.88,466.61a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,819.88,466.61Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M798.18,466.61a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,798.18,466.61Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M819.88,444.92a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,819.88,444.92Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M798.18,444.92a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,798.18,444.92Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M809,434.07a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,809,434.07Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M830.73,434.07a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,830.73,434.07Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M809,499.16a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,809,499.16Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M830.73,499.16a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,830.73,499.16Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M819.88,510a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,819.88,510Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M798.18,510a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,798.18,510Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M819.88,488.31a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,819.88,488.31Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M798.18,488.31a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,798.18,488.31Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M809,477.46a2.17,2.17,0,1,0,2.17,2.17,2.19,2.19,0,0,0-2.17-2.17Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M830.73,477.46a2.17,2.17,0,0,0-2.17,2.17,2.17,2.17,0,1,0,3.7-1.53A2.12,2.12,0,0,0,830.73,477.46Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M809,542.55a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,809,542.55Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M830.73,542.55a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,830.73,542.55Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M819.88,553.4a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,819.88,553.4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M798.18,553.4a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,798.18,553.4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M819.88,531.7a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,819.88,531.7Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M798.18,531.7a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,798.18,531.7Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M809,520.85a2.17,2.17,0,0,0-2.17,2.17,2.22,2.22,0,0,0,.37,1.22,2.18,2.18,0,0,0,3.6,0,2.22,2.22,0,0,0,.37-1.22A2.17,2.17,0,0,0,809,520.85Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M830.73,520.85a2.17,2.17,0,0,0-2.17,2.17,2.17,2.17,0,0,0,4.34,0A2.18,2.18,0,0,0,830.73,520.85Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M809,586a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,809,586Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M830.73,586a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,830.73,586Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M819.88,596.79a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,819.88,596.79Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M798.18,596.79a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,798.18,596.79Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M819.88,575.1a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,819.88,575.1Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M798.18,575.1a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,798.18,575.1Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M809,564.25a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,809,564.25Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M830.73,564.25a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,830.73,564.25Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M809,629.34a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,809,629.34Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M830.73,629.34a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,830.73,629.34Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M819.88,640.19a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,819.88,640.19Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M798.18,640.19a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,798.18,640.19Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M819.88,618.49a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,819.88,618.49Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M798.18,618.49a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,798.18,618.49Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M809,607.64a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,809,607.64Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M830.73,607.64a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,830.73,607.64Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M809,694.43a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,809,694.43Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M830.73,694.43a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,830.73,694.43Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M809,672.73a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,809,672.73Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M830.73,672.73a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,830.73,672.73Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M819.88,683.58a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,819.88,683.58Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M798.18,683.58a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,798.18,683.58Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M819.88,661.88a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,819.88,661.88Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M798.18,661.88a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,798.18,661.88Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M809,651a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,809,651Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M830.73,651a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,830.73,651Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M765.64,412.37a2.17,2.17,0,1,0,2.16,2.17A2.17,2.17,0,0,0,765.64,412.37Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M787.33,412.37a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,787.33,412.37Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M776.48,423.22a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,776.48,423.22Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M754.79,423.22a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,754.79,423.22Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M776.48,401.52a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,776.48,401.52Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M754.79,401.52a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,754.79,401.52Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M744.78,412.54a2.14,2.14,0,0,0-.84-.17,2.17,2.17,0,0,0,0,4.34,2.14,2.14,0,0,0,.84-.17,2.17,2.17,0,0,0,0-4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M765.64,455.76a2.17,2.17,0,1,0,2.16,2.17A2.17,2.17,0,0,0,765.64,455.76Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M787.33,455.76a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,787.33,455.76Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M776.48,466.61a2.17,2.17,0,1,0,2.17,2.17A2.18,2.18,0,0,0,776.48,466.61Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M754.79,466.61a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,754.79,466.61Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M776.48,444.92a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,776.48,444.92Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M754.79,444.92a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,754.79,444.92Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M744.78,455.93a2.14,2.14,0,0,0-.84-.17,2.17,2.17,0,0,0,0,4.34,2.14,2.14,0,0,0,.84-.17,2.17,2.17,0,0,0,0-4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M765.64,434.07a2.17,2.17,0,1,0,2.16,2.17A2.17,2.17,0,0,0,765.64,434.07Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M787.33,434.07a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,787.33,434.07Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M744.78,434.24a2.14,2.14,0,0,0-.84-.17,2.17,2.17,0,0,0,0,4.34,2.14,2.14,0,0,0,.84-.17,2.17,2.17,0,0,0,0-4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M765.64,499.16a2.17,2.17,0,1,0,2.16,2.17A2.17,2.17,0,0,0,765.64,499.16Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M787.33,499.16a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,787.33,499.16Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M776.48,510a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,776.48,510Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M754.79,510a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,754.79,510Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M776.48,488.31a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,776.48,488.31Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M754.79,488.31a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,754.79,488.31Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M744.78,499.33a2.14,2.14,0,0,0-.84-.17,2.17,2.17,0,0,0,0,4.34,2.14,2.14,0,0,0,.84-.17,2.17,2.17,0,0,0,0-4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M765.64,477.46a2.17,2.17,0,0,0-2.17,2.17,2.17,2.17,0,1,0,3.7-1.53A2.14,2.14,0,0,0,765.64,477.46Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M787.33,477.46a2.12,2.12,0,0,0-1.53.64,2.16,2.16,0,1,0,1.53-.64Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M744.78,477.63a2.14,2.14,0,0,0-.84-.17,2.17,2.17,0,0,0,0,4.34,2.14,2.14,0,0,0,.84-.17,2.16,2.16,0,0,0,.69-3.53A2.06,2.06,0,0,0,744.78,477.63Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M765.64,542.55a2.17,2.17,0,1,0,2.16,2.17A2.17,2.17,0,0,0,765.64,542.55Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M787.33,542.55a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,787.33,542.55Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M776.48,553.4a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,776.48,553.4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M754.79,553.4a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,754.79,553.4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M776.48,531.7a2.17,2.17,0,1,0,2.17,2.17A2.18,2.18,0,0,0,776.48,531.7Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M754.79,531.7a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,754.79,531.7Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M744.78,542.72a2.14,2.14,0,0,0-.84-.17,2.17,2.17,0,0,0,0,4.34,2.14,2.14,0,0,0,.84-.17,2.17,2.17,0,0,0,0-4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M765.64,520.85a2.17,2.17,0,0,0-2.17,2.17,2.17,2.17,0,1,0,4.33,0A2.17,2.17,0,0,0,765.64,520.85Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M787.33,520.85a2.17,2.17,0,0,0-2.17,2.17,2.22,2.22,0,0,0,.37,1.22,2.17,2.17,0,1,0,1.8-3.39Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M744.78,521a2.14,2.14,0,0,0-.84-.18,2.17,2.17,0,0,0-2.17,2.17,2.22,2.22,0,0,0,.37,1.22,2.17,2.17,0,0,0,2.64.78,2.15,2.15,0,0,0,1.33-2A2.16,2.16,0,0,0,744.78,521Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M765.64,586a2.17,2.17,0,1,0,2.16,2.17A2.17,2.17,0,0,0,765.64,586Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M787.33,586a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,787.33,586Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M776.48,596.79a2.17,2.17,0,1,0,2.17,2.17A2.18,2.18,0,0,0,776.48,596.79Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M754.79,596.79A2.17,2.17,0,1,0,757,599,2.17,2.17,0,0,0,754.79,596.79Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M776.48,575.1a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,776.48,575.1Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M754.79,575.1a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,754.79,575.1Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M744.78,586.12a2.14,2.14,0,0,0-.84-.17,2.17,2.17,0,0,0,0,4.34,2.2,2.2,0,0,0,2.17-2.17A2.17,2.17,0,0,0,744.78,586.12Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M765.64,564.25a2.17,2.17,0,1,0,2.16,2.17A2.17,2.17,0,0,0,765.64,564.25Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M787.33,564.25a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,787.33,564.25Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M744.78,564.42a2.14,2.14,0,0,0-.84-.17,2.17,2.17,0,1,0,0,4.34,2.14,2.14,0,0,0,.84-.17,2.17,2.17,0,0,0,0-4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M765.64,629.34a2.17,2.17,0,1,0,2.16,2.17A2.17,2.17,0,0,0,765.64,629.34Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M787.33,629.34a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,787.33,629.34Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M776.48,640.19a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,776.48,640.19Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M754.79,640.19a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,754.79,640.19Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M776.48,618.49a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,776.48,618.49Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M754.79,618.49a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,754.79,618.49Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M744.78,629.51a2.14,2.14,0,0,0-.84-.17,2.17,2.17,0,0,0,0,4.34,2.14,2.14,0,0,0,.84-.17,2.17,2.17,0,0,0,0-4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M765.64,607.64a2.17,2.17,0,1,0,2.16,2.17A2.17,2.17,0,0,0,765.64,607.64Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M787.33,607.64a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,787.33,607.64Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M744.78,607.81a2.14,2.14,0,0,0-.84-.17,2.17,2.17,0,1,0,0,4.34,2.14,2.14,0,0,0,.84-.17,2.17,2.17,0,0,0,0-4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M765.64,694.43a2.17,2.17,0,1,0,2.16,2.17A2.17,2.17,0,0,0,765.64,694.43Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M787.33,694.43a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,787.33,694.43Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M765.64,672.73a2.17,2.17,0,1,0,2.16,2.17A2.17,2.17,0,0,0,765.64,672.73Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M787.33,672.73a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,787.33,672.73Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M776.48,683.58a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,776.48,683.58Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M754.79,683.58a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,754.79,683.58Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M776.48,661.88a2.17,2.17,0,1,0,2.17,2.17A2.18,2.18,0,0,0,776.48,661.88Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M754.79,661.88a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,754.79,661.88Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M744.78,694.6a2.14,2.14,0,0,0-.84-.17,2.17,2.17,0,0,0,0,4.34,2.14,2.14,0,0,0,.84-.17,2.17,2.17,0,0,0,0-4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M744.78,672.9a2.14,2.14,0,0,0-.84-.17,2.17,2.17,0,1,0,0,4.34,2.14,2.14,0,0,0,.84-.17,2.17,2.17,0,0,0,0-4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M765.64,651a2.17,2.17,0,1,0,2.16,2.17A2.17,2.17,0,0,0,765.64,651Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M787.33,651a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,787.33,651Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M744.78,651.21a2.14,2.14,0,0,0-.84-.17,2.17,2.17,0,0,0,0,4.34,2.14,2.14,0,0,0,.84-.17,2.17,2.17,0,0,0,0-4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M722.24,412.37a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,722.24,412.37Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M733.09,423.22a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,733.09,423.22Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M711.39,423.22a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,711.39,423.22Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M733.09,401.52a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,733.09,401.52Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M711.39,401.52a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,711.39,401.52Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M722.24,455.76a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,722.24,455.76Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M733.09,466.61a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,733.09,466.61Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M711.39,466.61a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,711.39,466.61Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M733.09,444.92a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,733.09,444.92Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M711.39,444.92a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,711.39,444.92Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M722.24,434.07a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,722.24,434.07Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M722.24,499.16a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,722.24,499.16Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M733.09,510a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,733.09,510Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M711.39,510a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,711.39,510Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M733.09,488.31a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,733.09,488.31Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M711.39,488.31a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,711.39,488.31Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M722.24,477.46a2.19,2.19,0,0,0-2.17,2.17,2.17,2.17,0,1,0,2.17-2.17Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M722.24,542.55a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,722.24,542.55Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M733.09,553.4a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,733.09,553.4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M711.39,553.4a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,711.39,553.4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M733.09,531.7a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,733.09,531.7Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M711.39,531.7a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,711.39,531.7Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M722.24,520.85a2.17,2.17,0,0,0-2.17,2.17,2.22,2.22,0,0,0,.37,1.22,2.18,2.18,0,0,0,3.6,0,2.22,2.22,0,0,0,.37-1.22A2.17,2.17,0,0,0,722.24,520.85Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M722.24,586a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,722.24,586Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M733.09,596.79a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,733.09,596.79Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M711.39,596.79a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,711.39,596.79Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M733.09,575.1a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,733.09,575.1Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M711.39,575.1a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,711.39,575.1Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M722.24,564.25a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,722.24,564.25Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M722.24,629.34a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,722.24,629.34Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M733.09,640.19a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,733.09,640.19Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M711.39,640.19a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,711.39,640.19Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M733.09,618.49a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,733.09,618.49Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M711.39,618.49a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,711.39,618.49Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M722.24,607.64a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,722.24,607.64Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M722.24,694.43a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,722.24,694.43Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M722.24,672.73a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,722.24,672.73Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M733.09,683.58a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,733.09,683.58Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M711.39,683.58a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,711.39,683.58Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M733.09,661.88a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,733.09,661.88Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M711.39,661.88a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,711.39,661.88Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M722.24,651a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,722.24,651Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M678.85,412.37a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,678.85,412.37Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M700.55,412.37a2.17,2.17,0,1,0,2.16,2.17A2.18,2.18,0,0,0,700.55,412.37Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M689.7,423.22a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,689.7,423.22Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M668,423.22a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,668,423.22Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M689.7,401.52a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,689.7,401.52Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M668,401.52a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,668,401.52Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M657.59,412.41a3.13,3.13,0,0,0-.44,0,2.17,2.17,0,0,0,0,4.34,3.13,3.13,0,0,0,.44,0,2.17,2.17,0,0,0,1.36-3.34A2.21,2.21,0,0,0,657.59,412.41Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M678.85,455.76a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,678.85,455.76Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M700.55,455.76a2.17,2.17,0,1,0,2.16,2.17A2.18,2.18,0,0,0,700.55,455.76Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M689.7,466.61a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,689.7,466.61Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M668,466.61a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,668,466.61Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M689.7,444.92a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,689.7,444.92Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M668,444.92a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,668,444.92Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M657.59,455.81a2.12,2.12,0,0,0-.44-.05,2.17,2.17,0,1,0,0,4.34,2.12,2.12,0,0,0,.44,0,2.2,2.2,0,0,0,1.36-.91,2.22,2.22,0,0,0,.37-1.22,2.19,2.19,0,0,0-.37-1.21A2.16,2.16,0,0,0,657.59,455.81Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M678.85,434.07a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,678.85,434.07Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M700.55,434.07a2.17,2.17,0,1,0,2.16,2.17A2.18,2.18,0,0,0,700.55,434.07Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M657.59,434.11a2.12,2.12,0,0,0-.44,0,2.17,2.17,0,1,0,0,4.34,2.12,2.12,0,0,0,.44-.05,2.16,2.16,0,0,0,1.36-.91,2.19,2.19,0,0,0,.37-1.21A2.22,2.22,0,0,0,659,435,2.2,2.2,0,0,0,657.59,434.11Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M678.85,499.16a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,678.85,499.16Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M700.55,499.16a2.17,2.17,0,1,0,2.16,2.17A2.18,2.18,0,0,0,700.55,499.16Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M689.7,510a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,689.7,510Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M668,510a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,668,510Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M689.7,488.31a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,689.7,488.31Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M668,488.31a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,668,488.31Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M657.59,499.2a2.12,2.12,0,0,0-.44,0,2.17,2.17,0,1,0,0,4.34,2.12,2.12,0,0,0,.44-.05,2.16,2.16,0,0,0,1.36-.91,2.19,2.19,0,0,0,.37-1.21,2.22,2.22,0,0,0-.37-1.22A2.2,2.2,0,0,0,657.59,499.2Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M678.85,477.46a2.19,2.19,0,1,0,1.53.64A2.12,2.12,0,0,0,678.85,477.46Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M700.55,477.46a2.19,2.19,0,0,0-2.18,2.17,2.17,2.17,0,1,0,4.34,0,2.17,2.17,0,0,0-2.16-2.17Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M658.68,478.1a2.14,2.14,0,0,0-1.09-.6,3.13,3.13,0,0,0-.44,0,2.19,2.19,0,0,0-2.17,2.17,2.17,2.17,0,0,0,2.17,2.17,2.12,2.12,0,0,0,.44,0,2.17,2.17,0,0,0,1.36-3.34A1.69,1.69,0,0,0,658.68,478.1Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M678.85,542.55a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,678.85,542.55Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M700.55,542.55a2.17,2.17,0,1,0,2.16,2.17A2.18,2.18,0,0,0,700.55,542.55Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M689.7,553.4a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,689.7,553.4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M668,553.4a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,668,553.4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M689.7,531.7a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,689.7,531.7Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M668,531.7a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,668,531.7Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M657.59,542.6a2.12,2.12,0,0,0-.44-.05,2.17,2.17,0,0,0,0,4.34,2.12,2.12,0,0,0,.44,0,2.17,2.17,0,0,0,0-4.25Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M678.85,520.85a2.17,2.17,0,0,0-2.17,2.17,2.17,2.17,0,0,0,4,1.22A2.22,2.22,0,0,0,681,523,2.17,2.17,0,0,0,678.85,520.85Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M700.55,520.85a2.18,2.18,0,0,0-2.18,2.17,2.17,2.17,0,1,0,4.34,0A2.17,2.17,0,0,0,700.55,520.85Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M657.59,520.9a2.12,2.12,0,0,0-.44,0A2.17,2.17,0,0,0,655,523a2.22,2.22,0,0,0,.37,1.22,2.15,2.15,0,0,0,1.8,1,2.12,2.12,0,0,0,.44,0,2.2,2.2,0,0,0,1.36-.91,2.18,2.18,0,0,0,0-2.43A2.16,2.16,0,0,0,657.59,520.9Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M678.85,586a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,678.85,586Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M700.55,586a2.17,2.17,0,1,0,2.16,2.17A2.18,2.18,0,0,0,700.55,586Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M689.7,596.79a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,689.7,596.79Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M668,596.79a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,668,596.79Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M689.7,575.1a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,689.7,575.1Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M668,575.1a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,668,575.1Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M657.59,586a2.12,2.12,0,0,0-.44,0,2.17,2.17,0,0,0,0,4.34,2.12,2.12,0,0,0,.44,0,2.16,2.16,0,0,0,1.36-.91,2.18,2.18,0,0,0,0-2.43A2.16,2.16,0,0,0,657.59,586Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M678.85,564.25a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,678.85,564.25Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M700.55,564.25a2.17,2.17,0,1,0,2.16,2.17A2.18,2.18,0,0,0,700.55,564.25Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M657.59,564.29a2.12,2.12,0,0,0-.44,0,2.17,2.17,0,1,0,0,4.34,2.12,2.12,0,0,0,.44-.05,2.17,2.17,0,0,0,0-4.25Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M678.85,629.34a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,678.85,629.34Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M700.55,629.34a2.17,2.17,0,1,0,2.16,2.17A2.18,2.18,0,0,0,700.55,629.34Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M689.7,640.19a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,689.7,640.19Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M668,640.19a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,668,640.19Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M689.7,618.49a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,689.7,618.49Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M668,618.49a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,668,618.49Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M657.59,629.38a2.12,2.12,0,0,0-.44,0,2.17,2.17,0,0,0,0,4.34,2.12,2.12,0,0,0,.44,0,2.17,2.17,0,0,0,0-4.25Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M678.85,607.64a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,678.85,607.64Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M700.55,607.64a2.17,2.17,0,1,0,2.16,2.17A2.18,2.18,0,0,0,700.55,607.64Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M657.59,607.69a2.12,2.12,0,0,0-.44-.05,2.17,2.17,0,1,0,0,4.34,2.12,2.12,0,0,0,.44,0A2.2,2.2,0,0,0,659,611a2.22,2.22,0,0,0,.37-1.22,2.19,2.19,0,0,0-.37-1.21A2.16,2.16,0,0,0,657.59,607.69Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M678.85,694.43A2.17,2.17,0,1,0,681,696.6,2.17,2.17,0,0,0,678.85,694.43Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M700.55,694.43a2.17,2.17,0,1,0,2.16,2.17A2.18,2.18,0,0,0,700.55,694.43Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M678.85,672.73A2.17,2.17,0,1,0,681,674.9,2.17,2.17,0,0,0,678.85,672.73Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M700.55,672.73a2.17,2.17,0,1,0,2.16,2.17A2.18,2.18,0,0,0,700.55,672.73Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M689.7,683.58a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,689.7,683.58Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M668,683.58a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,668,683.58Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M689.7,661.88a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,689.7,661.88Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M668,661.88a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,668,661.88Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M657.59,694.47a2.12,2.12,0,0,0-.44,0,2.17,2.17,0,1,0,0,4.34,3.13,3.13,0,0,0,.44,0,2.21,2.21,0,0,0,1.36-.92,2.16,2.16,0,0,0,0-2.42A2.21,2.21,0,0,0,657.59,694.47Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M657.59,672.78a2.12,2.12,0,0,0-.44,0,2.17,2.17,0,1,0,0,4.34,2.12,2.12,0,0,0,.44,0,2.2,2.2,0,0,0,1.36-.91,2.22,2.22,0,0,0,.37-1.22,2.19,2.19,0,0,0-.37-1.21A2.16,2.16,0,0,0,657.59,672.78Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M678.85,651a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,678.85,651Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M700.55,651a2.17,2.17,0,1,0,2.16,2.17A2.18,2.18,0,0,0,700.55,651Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M657.59,651.08a2.12,2.12,0,0,0-.44,0,2.17,2.17,0,0,0,0,4.34,2.12,2.12,0,0,0,.44,0,2.16,2.16,0,0,0,1.36-.91,2.19,2.19,0,0,0,.37-1.21A2.22,2.22,0,0,0,659,652,2.2,2.2,0,0,0,657.59,651.08Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M635.45,412.37a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,635.45,412.37Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M646.3,423.22a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,646.3,423.22Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M624.61,423.22a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,624.61,423.22Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M646.3,401.52a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,646.3,401.52Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M624.61,401.52a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,624.61,401.52Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M614.19,412.41a3,3,0,0,0-.43,0,2.22,2.22,0,0,0-1.22.37,2.18,2.18,0,0,0,0,3.6,2.22,2.22,0,0,0,1.22.37,3,3,0,0,0,.43,0,2.19,2.19,0,0,0,1.37-.92,2.16,2.16,0,0,0,0-2.42A2.23,2.23,0,0,0,614.19,412.41Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M635.45,455.76a2.17,2.17,0,1,0,2.17,2.17A2.18,2.18,0,0,0,635.45,455.76Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M646.3,466.61a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,646.3,466.61Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M624.61,466.61a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,624.61,466.61Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M646.3,444.92a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,646.3,444.92Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M624.61,444.92a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,624.61,444.92Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M614.19,455.81a2,2,0,0,0-.43-.05,2.22,2.22,0,0,0-1.22.37,2.18,2.18,0,0,0,0,3.6,2.14,2.14,0,0,0,1.22.37,2,2,0,0,0,.43,0,2.16,2.16,0,0,0,1.74-2.13,2.16,2.16,0,0,0-1.74-2.12Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M635.45,434.07a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,635.45,434.07Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M614.19,434.11a2,2,0,0,0-.43,0,2.14,2.14,0,0,0-1.22.37,2.18,2.18,0,0,0,0,3.6,2.22,2.22,0,0,0,1.22.37,2,2,0,0,0,.43-.05,2.16,2.16,0,0,0,1.74-2.12,2.16,2.16,0,0,0-1.74-2.13Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M635.45,499.16a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,635.45,499.16Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M646.3,510a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,646.3,510Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M624.61,510a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,624.61,510Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M646.3,488.31a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,646.3,488.31Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M624.61,488.31a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,624.61,488.31Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M614.19,499.2a2,2,0,0,0-.43,0,2.14,2.14,0,0,0-1.22.37,2.18,2.18,0,0,0,0,3.6,2.22,2.22,0,0,0,1.22.37,2,2,0,0,0,.43-.05,2.16,2.16,0,0,0,1.74-2.12,2.16,2.16,0,0,0-1.74-2.13Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M635.45,477.46a2.12,2.12,0,0,0-1.53.64,2.16,2.16,0,1,0,1.53-.64Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M615.29,478.1a2.13,2.13,0,0,0-1.1-.6,3,3,0,0,0-.43,0,2.22,2.22,0,0,0-1.22.37,2.69,2.69,0,0,0-.32.27,2.15,2.15,0,0,0-.63,1.53,2.18,2.18,0,0,0,.95,1.8,2.22,2.22,0,0,0,1.22.37,2,2,0,0,0,.43,0,2.19,2.19,0,0,0,1.37-.92,2.16,2.16,0,0,0,0-2.42A1.69,1.69,0,0,0,615.29,478.1Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M635.45,542.55a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,635.45,542.55Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M646.3,553.4a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,646.3,553.4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M624.61,553.4a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,624.61,553.4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M646.3,531.7a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,646.3,531.7Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M624.61,531.7a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,624.61,531.7Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M614.19,542.6a2,2,0,0,0-.43-.05,2.22,2.22,0,0,0-1.22.37,2.18,2.18,0,0,0,0,3.6,2.22,2.22,0,0,0,1.22.37,2,2,0,0,0,.43,0,2.19,2.19,0,0,0,1.37-.92,2.16,2.16,0,0,0-1.37-3.33Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M635.45,520.85a2.18,2.18,0,0,0-2.17,2.17,2.22,2.22,0,0,0,.37,1.22,2.18,2.18,0,0,0,3.6,0,2.14,2.14,0,0,0,.37-1.22A2.17,2.17,0,0,0,635.45,520.85Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M614.19,520.9a2,2,0,0,0-.43,0,2.15,2.15,0,0,0-1.22.38,2.18,2.18,0,0,0-.95,1.79,2.14,2.14,0,0,0,.37,1.22,2,2,0,0,0,.58.58,2.14,2.14,0,0,0,1.22.37,2,2,0,0,0,.43,0,2.17,2.17,0,0,0,1.37-3.34A2.18,2.18,0,0,0,614.19,520.9Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M635.45,586a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,635.45,586Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M646.3,596.79a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,646.3,596.79Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M624.61,596.79a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,624.61,596.79Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M646.3,575.1a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,646.3,575.1Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M624.61,575.1a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,624.61,575.1Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M614.19,586a2,2,0,0,0-.43,0,2.14,2.14,0,0,0-1.22.37,2.17,2.17,0,0,0,0,3.59,2.15,2.15,0,0,0,1.22.38,2,2,0,0,0,.43,0,2.18,2.18,0,0,0,1.37-.91,2.18,2.18,0,0,0,0-2.43A2.18,2.18,0,0,0,614.19,586Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M635.45,564.25a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,635.45,564.25Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M614.19,564.29a2,2,0,0,0-.43,0,2.22,2.22,0,0,0-1.22.37,2.18,2.18,0,0,0,0,3.6,2.22,2.22,0,0,0,1.22.37,2,2,0,0,0,.43-.05,2.17,2.17,0,0,0,0-4.25Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M635.45,629.34a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,635.45,629.34Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M646.3,640.19a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,646.3,640.19Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M624.61,640.19a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,624.61,640.19Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M646.3,618.49a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,646.3,618.49Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M624.61,618.49a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,624.61,618.49Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M614.19,629.38a2,2,0,0,0-.43,0,2.22,2.22,0,0,0-1.22.37,2.18,2.18,0,0,0,0,3.6,2.22,2.22,0,0,0,1.22.37,2,2,0,0,0,.43,0,2.17,2.17,0,0,0,0-4.25Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M635.45,607.64a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,635.45,607.64Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M614.19,607.69a2,2,0,0,0-.43-.05,2.22,2.22,0,0,0-1.22.37,2.18,2.18,0,0,0,0,3.6,2.14,2.14,0,0,0,1.22.37,2,2,0,0,0,.43,0,2.16,2.16,0,0,0,1.74-2.13,2.16,2.16,0,0,0-1.74-2.12Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M635.45,694.43a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,635.45,694.43Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M635.45,672.73a2.17,2.17,0,1,0,2.17,2.17A2.18,2.18,0,0,0,635.45,672.73Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M646.3,683.58a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,646.3,683.58Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M624.61,683.58a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,624.61,683.58Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M646.3,661.88a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,646.3,661.88Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M624.61,661.88a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,624.61,661.88Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M614.19,694.47a2,2,0,0,0-.43,0,2.22,2.22,0,0,0-1.22.37,2.18,2.18,0,0,0,0,3.6,2.22,2.22,0,0,0,1.22.37,3,3,0,0,0,.43,0,2.23,2.23,0,0,0,1.37-.92,2.16,2.16,0,0,0,0-2.42A2.23,2.23,0,0,0,614.19,694.47Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M614.19,672.78a2,2,0,0,0-.43,0,2.22,2.22,0,0,0-1.22.37,2.18,2.18,0,0,0,0,3.6,2.14,2.14,0,0,0,1.22.37,2,2,0,0,0,.43,0,2.16,2.16,0,0,0,1.74-2.13,2.16,2.16,0,0,0-1.74-2.12Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M635.45,651a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,635.45,651Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M614.19,651.08a2,2,0,0,0-.43,0,2.14,2.14,0,0,0-1.22.37,2.18,2.18,0,0,0,0,3.6,2.22,2.22,0,0,0,1.22.37,2,2,0,0,0,.43,0,2.16,2.16,0,0,0,1.74-2.12,2.16,2.16,0,0,0-1.74-2.13Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M592.06,412.37a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,592.06,412.37Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M602.91,423.22a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,602.91,423.22Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M581.21,423.22a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,581.21,423.22Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M602.91,401.52a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,602.91,401.52Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M581.21,401.52a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,581.21,401.52Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M592.06,455.76a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,592.06,455.76Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M602.91,466.61a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,602.91,466.61Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M581.21,466.61a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,581.21,466.61Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M602.91,444.92a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,602.91,444.92Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M581.21,444.92a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,581.21,444.92Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M592.06,434.07a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,592.06,434.07Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M592.06,499.16a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,592.06,499.16Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M602.91,510a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,602.91,510Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M581.21,510a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,581.21,510Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M602.91,488.31a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,602.91,488.31Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M581.21,488.31a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,581.21,488.31Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M592.06,477.46a2.19,2.19,0,0,0-2.17,2.17,2.17,2.17,0,0,0,4.34,0,2.19,2.19,0,0,0-2.17-2.17Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M592.06,542.55a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,592.06,542.55Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M602.91,553.4a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,602.91,553.4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M581.21,553.4a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,581.21,553.4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M602.91,531.7a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,602.91,531.7Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M581.21,531.7a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,581.21,531.7Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M592.06,520.85a2.17,2.17,0,0,0-2.17,2.17,2.22,2.22,0,0,0,.37,1.22,2.18,2.18,0,0,0,3.6,0,2.22,2.22,0,0,0,.37-1.22A2.17,2.17,0,0,0,592.06,520.85Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M592.06,586a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,592.06,586Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M602.91,596.79a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,602.91,596.79Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M581.21,596.79a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,581.21,596.79Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M602.91,575.1a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,602.91,575.1Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M581.21,575.1a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,581.21,575.1Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M592.06,564.25a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,592.06,564.25Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M592.06,629.34a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,592.06,629.34Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M602.91,640.19a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,602.91,640.19Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M581.21,640.19a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,581.21,640.19Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M602.91,618.49a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,602.91,618.49Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M581.21,618.49a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,581.21,618.49Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M592.06,607.64a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,592.06,607.64Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M592.06,694.43a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,592.06,694.43Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M592.06,672.73a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,592.06,672.73Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M602.91,683.58a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,602.91,683.58Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M581.21,683.58a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,581.21,683.58Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M602.91,661.88a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,602.91,661.88Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M581.21,661.88a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,581.21,661.88Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M592.06,651a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,592.06,651Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M548.67,412.37a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,548.67,412.37Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M570.36,412.37a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,570.36,412.37Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M559.52,423.22a2.17,2.17,0,1,0,2.16,2.17A2.17,2.17,0,0,0,559.52,423.22Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M537.82,423.22a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,537.82,423.22Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M559.52,401.52a2.17,2.17,0,1,0,2.16,2.17A2.17,2.17,0,0,0,559.52,401.52Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M537.82,401.52a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,537.82,401.52Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M527.81,412.54a2.14,2.14,0,0,0-.84-.17,2.17,2.17,0,0,0,0,4.34,2.14,2.14,0,0,0,.84-.17,2.17,2.17,0,0,0,0-4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M548.67,455.76a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,548.67,455.76Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M570.36,455.76a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,570.36,455.76Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M559.52,466.61a2.17,2.17,0,1,0,2.16,2.17A2.17,2.17,0,0,0,559.52,466.61Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M537.82,466.61a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,537.82,466.61Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M559.52,444.92a2.17,2.17,0,1,0,2.16,2.17A2.17,2.17,0,0,0,559.52,444.92Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M537.82,444.92a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,537.82,444.92Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M527.81,455.93a2.14,2.14,0,0,0-.84-.17,2.17,2.17,0,0,0,0,4.34,2.14,2.14,0,0,0,.84-.17,2.17,2.17,0,0,0,0-4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M548.67,434.07a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,548.67,434.07Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M570.36,434.07a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,570.36,434.07Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M527.81,434.24a2.14,2.14,0,0,0-.84-.17,2.17,2.17,0,0,0,0,4.34,2.14,2.14,0,0,0,.84-.17,2.17,2.17,0,0,0,0-4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M548.67,499.16a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,548.67,499.16Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M570.36,499.16a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,570.36,499.16Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M559.52,510a2.17,2.17,0,1,0,2.16,2.17A2.17,2.17,0,0,0,559.52,510Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M537.82,510a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,537.82,510Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M559.52,488.31a2.17,2.17,0,1,0,2.16,2.17A2.17,2.17,0,0,0,559.52,488.31Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M537.82,488.31a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,537.82,488.31Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M527.81,499.33a2.14,2.14,0,0,0-.84-.17,2.17,2.17,0,0,0,0,4.34,2.14,2.14,0,0,0,.84-.17,2.17,2.17,0,0,0,0-4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M548.67,477.46a2.17,2.17,0,0,0-2.17,2.17,2.17,2.17,0,1,0,3.7-1.53A2.12,2.12,0,0,0,548.67,477.46Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M570.36,477.46a2.12,2.12,0,0,0-1.53.64,2.16,2.16,0,1,0,1.53-.64Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M527.81,477.63a2.18,2.18,0,0,0-3,2A2.17,2.17,0,0,0,527,481.8a2.14,2.14,0,0,0,.84-.17,2.17,2.17,0,0,0,1.33-2,2.12,2.12,0,0,0-.64-1.53A2.06,2.06,0,0,0,527.81,477.63Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M548.67,542.55a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,548.67,542.55Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M570.36,542.55a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,570.36,542.55Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M559.52,553.4a2.17,2.17,0,1,0,2.16,2.17A2.17,2.17,0,0,0,559.52,553.4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M537.82,553.4a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,537.82,553.4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M559.52,531.7a2.17,2.17,0,1,0,2.16,2.17A2.17,2.17,0,0,0,559.52,531.7Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M537.82,531.7a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,537.82,531.7Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M527.81,542.72a2.14,2.14,0,0,0-.84-.17,2.17,2.17,0,0,0,0,4.34,2.14,2.14,0,0,0,.84-.17,2.17,2.17,0,0,0,0-4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M548.67,520.85A2.17,2.17,0,0,0,546.5,523a2.16,2.16,0,0,0,2.17,2.17,2.17,2.17,0,0,0,1.8-1,2.22,2.22,0,0,0,.37-1.22A2.18,2.18,0,0,0,548.67,520.85Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M570.36,520.85a2.17,2.17,0,0,0-2.17,2.17,2.22,2.22,0,0,0,.37,1.22,2.17,2.17,0,1,0,1.8-3.39Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M527.81,521a2.14,2.14,0,0,0-.84-.18A2.17,2.17,0,0,0,524.8,523a2.22,2.22,0,0,0,.37,1.22,2.17,2.17,0,0,0,2.64.78,2.15,2.15,0,0,0,1.33-2A2.16,2.16,0,0,0,527.81,521Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M548.67,586a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,548.67,586Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M570.36,586a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,570.36,586Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M559.52,596.79a2.17,2.17,0,1,0,2.16,2.17A2.17,2.17,0,0,0,559.52,596.79Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M537.82,596.79A2.17,2.17,0,1,0,540,599,2.17,2.17,0,0,0,537.82,596.79Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M559.52,575.1a2.17,2.17,0,1,0,2.16,2.17A2.17,2.17,0,0,0,559.52,575.1Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M537.82,575.1a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,537.82,575.1Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M527.81,586.12A2.14,2.14,0,0,0,527,586a2.17,2.17,0,0,0,0,4.34,2.2,2.2,0,0,0,2.17-2.17A2.17,2.17,0,0,0,527.81,586.12Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M548.67,564.25a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,548.67,564.25Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M570.36,564.25a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,570.36,564.25Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M527.81,564.42a2.14,2.14,0,0,0-.84-.17,2.17,2.17,0,0,0,0,4.34,2.14,2.14,0,0,0,.84-.17,2.17,2.17,0,0,0,0-4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M548.67,629.34a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,548.67,629.34Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M570.36,629.34a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,570.36,629.34Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M559.52,640.19a2.17,2.17,0,1,0,2.16,2.17A2.17,2.17,0,0,0,559.52,640.19Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M537.82,640.19a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,537.82,640.19Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M559.52,618.49a2.17,2.17,0,1,0,2.16,2.17A2.17,2.17,0,0,0,559.52,618.49Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M537.82,618.49a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,537.82,618.49Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M527.81,629.51a2.14,2.14,0,0,0-.84-.17,2.17,2.17,0,0,0,0,4.34,2.14,2.14,0,0,0,.84-.17,2.17,2.17,0,0,0,0-4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M548.67,607.64a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,548.67,607.64Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M570.36,607.64a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,570.36,607.64Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M527.81,607.81a2.14,2.14,0,0,0-.84-.17,2.17,2.17,0,1,0,0,4.34,2.14,2.14,0,0,0,.84-.17,2.17,2.17,0,0,0,0-4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M548.67,694.43a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,548.67,694.43Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M570.36,694.43a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,570.36,694.43Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M548.67,672.73a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,548.67,672.73Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M570.36,672.73a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,570.36,672.73Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M559.52,683.58a2.17,2.17,0,1,0,2.16,2.17A2.17,2.17,0,0,0,559.52,683.58Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M537.82,683.58a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,537.82,683.58Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M559.52,661.88a2.17,2.17,0,1,0,2.16,2.17A2.17,2.17,0,0,0,559.52,661.88Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M537.82,661.88a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,537.82,661.88Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M527.81,694.6a2.14,2.14,0,0,0-.84-.17,2.17,2.17,0,0,0,0,4.34,2.14,2.14,0,0,0,.84-.17,2.17,2.17,0,0,0,0-4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M527.81,672.9a2.14,2.14,0,0,0-.84-.17,2.17,2.17,0,1,0,0,4.34,2.14,2.14,0,0,0,.84-.17,2.17,2.17,0,0,0,0-4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M548.67,651a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,548.67,651Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M570.36,651a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,570.36,651Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M527.81,651.21A2.14,2.14,0,0,0,527,651a2.17,2.17,0,0,0,0,4.34,2.14,2.14,0,0,0,.84-.17,2.17,2.17,0,0,0,0-4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M505.27,412.37a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,505.27,412.37Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M516.12,423.22a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,516.12,423.22Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M494.42,423.22a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,494.42,423.22Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M516.12,401.52a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,516.12,401.52Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M494.42,401.52a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,494.42,401.52Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M505.27,455.76a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,505.27,455.76Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M516.12,466.61a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,516.12,466.61Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M494.42,466.61a2.17,2.17,0,1,0,2.17,2.17A2.18,2.18,0,0,0,494.42,466.61Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M516.12,444.92a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,516.12,444.92Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M494.42,444.92a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,494.42,444.92Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M505.27,434.07a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,505.27,434.07Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M505.27,499.16a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,505.27,499.16Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M516.12,510a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,516.12,510Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M494.42,510a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,494.42,510Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M516.12,488.31a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,516.12,488.31Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M494.42,488.31a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,494.42,488.31Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M505.27,477.46a2.12,2.12,0,0,0-1.53.64,2.16,2.16,0,1,0,1.53-.64Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M505.27,542.55a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,505.27,542.55Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M516.12,553.4a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,516.12,553.4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M494.42,553.4a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,494.42,553.4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M516.12,531.7a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,516.12,531.7Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M494.42,531.7a2.17,2.17,0,1,0,2.17,2.17A2.18,2.18,0,0,0,494.42,531.7Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M505.27,520.85A2.17,2.17,0,0,0,503.1,523a2.22,2.22,0,0,0,.37,1.22,2.17,2.17,0,1,0,1.8-3.39Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M505.27,586a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,505.27,586Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M516.12,596.79a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,516.12,596.79Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M494.42,596.79a2.17,2.17,0,1,0,2.17,2.17A2.18,2.18,0,0,0,494.42,596.79Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M516.12,575.1a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,516.12,575.1Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M494.42,575.1a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,494.42,575.1Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M505.27,564.25a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,505.27,564.25Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M505.27,629.34a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,505.27,629.34Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M516.12,640.19a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,516.12,640.19Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M494.42,640.19a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,494.42,640.19Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M516.12,618.49a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,516.12,618.49Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M494.42,618.49a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,494.42,618.49Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M505.27,607.64a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,505.27,607.64Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M505.27,694.43a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,505.27,694.43Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M505.27,672.73a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,505.27,672.73Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M516.12,683.58a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,516.12,683.58Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M494.42,683.58a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,494.42,683.58Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M516.12,661.88a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,516.12,661.88Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M494.42,661.88a2.17,2.17,0,1,0,2.17,2.17A2.18,2.18,0,0,0,494.42,661.88Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M505.27,651a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,505.27,651Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M461.88,412.37a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,461.88,412.37Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M483.58,412.37a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,483.58,412.37Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M472.73,423.22a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,472.73,423.22Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M451,423.22a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,451,423.22Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M472.73,401.52a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,472.73,401.52Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M451,401.52a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,451,401.52Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M440.18,412.37a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,440.18,412.37Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M461.88,455.76a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,461.88,455.76Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M483.58,455.76a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,483.58,455.76Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M472.73,466.61a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,472.73,466.61Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M451,466.61a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,451,466.61Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M472.73,444.92a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,472.73,444.92Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M451,444.92a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,451,444.92Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M440.18,455.76a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,440.18,455.76Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M461.88,434.07a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,461.88,434.07Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M483.58,434.07a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,483.58,434.07Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M440.18,434.07a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,440.18,434.07Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M461.88,499.16a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,461.88,499.16Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M483.58,499.16a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,483.58,499.16Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M472.73,510a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,472.73,510Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M451,510a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,451,510Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M472.73,488.31a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,472.73,488.31Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M451,488.31a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,451,488.31Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M440.18,499.16a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,440.18,499.16Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M461.88,477.46a2.17,2.17,0,1,0,2.17,2.17,2.19,2.19,0,0,0-2.17-2.17Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M483.58,477.46a2.17,2.17,0,0,0-2.17,2.17,2.17,2.17,0,1,0,3.7-1.53A2.14,2.14,0,0,0,483.58,477.46Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M440.18,477.46a2.12,2.12,0,0,0-1.53.64,2.16,2.16,0,1,0,1.53-.64Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M461.88,542.55a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,461.88,542.55Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M483.58,542.55a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,483.58,542.55Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M472.73,553.4a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,472.73,553.4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M451,553.4a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,451,553.4Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M472.73,531.7a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,472.73,531.7Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M451,531.7a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,451,531.7Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M440.18,542.55a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,440.18,542.55Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M461.88,520.85a2.17,2.17,0,0,0-2.17,2.17,2.22,2.22,0,0,0,.37,1.22,2.18,2.18,0,0,0,3.6,0,2.22,2.22,0,0,0,.37-1.22A2.17,2.17,0,0,0,461.88,520.85Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M483.58,520.85a2.17,2.17,0,0,0-2.17,2.17,2.17,2.17,0,0,0,4.34,0A2.18,2.18,0,0,0,483.58,520.85Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M440.18,520.85A2.17,2.17,0,0,0,438,523a2.22,2.22,0,0,0,.37,1.22,2.18,2.18,0,0,0,3.6,0,2.22,2.22,0,0,0,.37-1.22A2.17,2.17,0,0,0,440.18,520.85Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M461.88,586a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,461.88,586Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M483.58,586a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,483.58,586Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M472.73,596.79A2.17,2.17,0,1,0,474.9,599,2.17,2.17,0,0,0,472.73,596.79Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M451,596.79A2.17,2.17,0,1,0,453.2,599,2.17,2.17,0,0,0,451,596.79Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M472.73,575.1a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,472.73,575.1Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M451,575.1a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,451,575.1Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M440.18,586a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,440.18,586Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M461.88,564.25a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,461.88,564.25Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M483.58,564.25a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,483.58,564.25Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M440.18,564.25a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,440.18,564.25Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M461.88,629.34a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,461.88,629.34Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M483.58,629.34a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,483.58,629.34Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M472.73,640.19a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,472.73,640.19Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M451,640.19a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,451,640.19Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M472.73,618.49a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,472.73,618.49Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M451,618.49a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,451,618.49Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M440.18,629.34a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,440.18,629.34Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M461.88,607.64a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,461.88,607.64Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M483.58,607.64a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,483.58,607.64Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M440.18,607.64a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,440.18,607.64Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M461.88,694.43a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,461.88,694.43Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M483.58,694.43a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,483.58,694.43Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M461.88,672.73a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,461.88,672.73Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M483.58,672.73a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,483.58,672.73Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M472.73,683.58a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,472.73,683.58Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M451,683.58a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,451,683.58Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M472.73,661.88a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,472.73,661.88Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M451,661.88a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,451,661.88Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M440.18,694.43a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,440.18,694.43Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M440.18,672.73a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,440.18,672.73Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M461.88,651a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,461.88,651Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M483.58,651a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,483.58,651Z"
						transform="translate(318.33 355.79)"
					/>
					<path
						d="M440.18,651a2.17,2.17,0,1,0,2.17,2.17A2.17,2.17,0,0,0,440.18,651Z"
						transform="translate(318.33 355.79)"
					/>
				</g>
			</g>
			<g id="VoorTuin">
				<rect
					x="958.49"
					y="366.72"
					width="179.33"
					height="321.58"
					fill="#bcbdc0"
				/>
				<rect
					x="728.57"
					y="-12.38"
					width="2.5"
					height="368.2"
					transform="matrix(0.87, -0.49, 0.49, 0.87, 327.11, 732.98)"
				/>
				<rect
					x="545.72"
					y="170.47"
					width="368.2"
					height="2.5"
					transform="translate(542.7 1081.27) rotate(-60.85)"
				/>
				<path
					d="M827.13,340.17H632.51V3.28H827.13Zm-179.32-15.3h164V18.58h-164Z"
					transform="translate(318.33 355.79)"
				/>
			</g>
			<rect
				x="779.16"
				y="688.31"
				width="179.33"
				height="67.99"
			/>
			<path
				d="M647.81,408.15H453.18V324.87H647.81Zm-179.33-15.3h164V340.17h-164Z"
				transform="translate(318.33 355.79)"
			/>
			<rect
				x="779.16"
				y="587.88"
				width="179.33"
				height="91.26"
			/>
			<path
				d="M647.81,331H453.18V224.44H647.81ZM468.48,315.7h164v-76h-164Z"
				transform="translate(318.33 355.79)"
			/>
			<g id="NachtKast2">
				<rect
					x="773.13"
					y="607.55"
					width="38.67"
					height="38.67"
					transform="translate(1737.69 190.21) rotate(90)"
					fill="#fff"
				/>
				<path
					d="M811.8,647.47H773.13l-1.25-1.25V607.55l1.25-1.25H811.8l1.25,1.25v38.67ZM774.38,645h36.17V608.8H774.38Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M792.47,640.92a14,14,0,1,1,14-14A14,14,0,0,1,792.47,640.92Zm0-25.57A11.54,11.54,0,1,0,804,626.89,11.55,11.55,0,0,0,792.47,615.35Z"
					transform="translate(318.33 355.79)"
				/>
			</g>
			<g id="Bed">
				<path
					d="M634.72,420.82H776.47a0,0,0,0,1,0,0V624.61a8.63,8.63,0,0,1-8.63,8.63H643.35a8.63,8.63,0,0,1-8.63-8.63V420.82A0,0,0,0,1,634.72,420.82Z"
					transform="translate(1550.96 177.23) rotate(90)"
					fill="#fff"
				/>
				<path
					d="M811.8,599.16H608a9.9,9.9,0,0,1-9.88-9.88V464.79a9.9,9.9,0,0,1,9.88-9.88H811.8l1.25,1.25V597.91ZM608,457.41a7.39,7.39,0,0,0-7.38,7.38V589.28a7.39,7.39,0,0,0,7.38,7.38H810.55V457.41Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M802.6,524.24H773.13L771.88,523V464.26l1.25-1.25H802.6l1.25,1.25V523Zm-28.22-2.5h27V465.51h-27Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M802.6,591.06H773.13l-1.25-1.25V531.08l1.25-1.25H802.6l1.25,1.25v58.73Zm-28.22-2.5h27V532.33h-27Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M763.93,552.66H743.1l-1.25-1.25V456.16l1.25-1.25h20.83l1.25,1.25v95.25Zm-19.58-2.5h18.33V457.41H744.35Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M718.32,598.79l-2.14-.88v-46.5l1.25-1.25h46.5l.88,2.13Zm.36-46.13v42.23l42.23-42.23Z"
					transform="translate(318.33 355.79)"
				/>
				<rect
					x="1070.6"
					y="811.95"
					width="2.5"
					height="95.24"
				/>
			</g>
			<g id="NachKast1">
				<rect
					x="773.13"
					y="407.85"
					width="38.67"
					height="38.67"
					transform="translate(1537.99 -9.5) rotate(90)"
					fill="#fff"
				/>
				<path
					d="M811.8,447.77H773.13l-1.25-1.25V407.85l1.25-1.25H811.8l1.25,1.25v38.67Zm-37.42-2.5h36.17V409.1H774.38Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M792.47,441.21a14,14,0,1,1,14-14A14,14,0,0,1,792.47,441.21Zm0-25.56A11.53,11.53,0,1,0,804,427.18,11.54,11.54,0,0,0,792.47,415.65Z"
					transform="translate(318.33 355.79)"
				/>
			</g>
			<g id="Tuin">
				<rect
					x="964.98"
					y="695.46"
					width="165.16"
					height="68.18"
					fill="#fff"
				/>
				<path
					d="M811.8,409.1H646.65l-1.25-1.25V339.67l1.25-1.25H811.8l1.25,1.25v68.18Zm-39.92-68.18h-124V406.6H810.55V340.92H771.88"
					transform="translate(318.33 355.79)"
				/>
				<rect
					x="964.98"
					y="728.3"
					width="165.16"
					height="2.5"
				/>
				<rect
					x="975.53"
					y="708.14"
					width="2.5"
					height="42.81"
				/>
				<rect
					x="987.33"
					y="708.14"
					width="2.5"
					height="42.81"
				/>
				<rect
					x="999.12"
					y="708.14"
					width="2.5"
					height="42.81"
				/>
				<rect
					x="1010.92"
					y="708.14"
					width="2.5"
					height="42.81"
				/>
				<rect
					x="1022.72"
					y="708.14"
					width="2.5"
					height="42.81"
				/>
				<rect
					x="1034.51"
					y="708.14"
					width="2.5"
					height="42.81"
				/>
				<rect
					x="1046.31"
					y="708.14"
					width="2.5"
					height="42.81"
				/>
				<rect
					x="1058.11"
					y="708.14"
					width="2.5"
					height="42.81"
				/>
				<rect
					x="1069.9"
					y="708.14"
					width="2.5"
					height="42.81"
				/>
				<rect
					x="1081.7"
					y="708.14"
					width="2.5"
					height="42.81"
				/>
				<rect
					x="1093.5"
					y="708.14"
					width="2.5"
					height="42.81"
				/>
				<rect
					x="1105.3"
					y="708.14"
					width="2.5"
					height="42.81"
				/>
				<rect
					x="1117.09"
					y="708.14"
					width="2.5"
					height="42.81"
				/>
			</g>
			<g id="Woonkamer">
				<rect
					x="430.93"
					y="580.48"
					width="251.23"
					height="253.4"
					fill="#fff"
				/>
				<rect
					x="430.93"
					y="580.48"
					width="251.23"
					height="253.4"
					fill="#bcbdc0"
				/>
				<path
					d="M363.82,479.34H112.6l-1.25-1.25V224.69l1.25-1.25H363.82l1.25,1.25v253.4Zm-250-2.5H362.57V225.94H113.85Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M238.21,477.17A126.87,126.87,0,1,1,365.07,350.3,127,127,0,0,1,238.21,477.17Zm0-251.23A124.37,124.37,0,1,0,362.57,350.3,124.5,124.5,0,0,0,238.21,225.94Z"
					transform="translate(318.33 355.79)"
				/>
				<rect
					x="175.67"
					y="333.92"
					width="192.12"
					height="34.93"
					transform="translate(238.67 978.9) rotate(-90)"
					fill="#fff"
				/>
				<ellipse
					cx="590.06"
					cy="707.18"
					rx="12.24"
					ry="36.41"
					fill="#fff"
				/>
				<line
					x1="590.06"
					y1="788.68"
					x2="590.06"
					y2="625.67"
					fill="#fff"
				/>
				<path
					d="M289.19,448.7H254.26L253,447.45V255.33l1.25-1.25h34.93l1.25,1.25V447.45Zm-33.68-2.5h32.43V256.58H255.51Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M271.73,389.05c-8.77,0-13.5-19.41-13.5-37.66s4.73-37.66,13.5-37.66,13.49,19.4,13.49,37.66S280.49,389.05,271.73,389.05Zm0-72.82c-5.2,0-11,14.44-11,35.16s5.8,35.16,11,35.16,11-14.44,11-35.16S276.93,316.23,271.73,316.23Z"
					transform="translate(318.33 355.79)"
				/>
				<rect
					x="588.81"
					y="625.67"
					width="2.5"
					height="163.01"
				/>
				<rect
					x="300.1"
					y="324.93"
					width="252.88"
					height="52.4"
					transform="translate(393.75 1133.46) rotate(-90)"
					fill="#fff"
				/>
				<line
					x1="753.61"
					y1="788.68"
					x2="753.61"
					y2="625.67"
					fill="#fff"
				/>
				<path
					d="M452.74,478.82H399.28L398,477.57V224.69l1.25-1.25h53.46l1.25,1.25V477.57Zm-52.21-2.5h51V225.94h-51Z"
					transform="translate(318.33 355.79)"
				/>
				<circle
					cx="183.5"
					cy="351.39"
					r="36.81"
					transform="translate(120.4 815.15) rotate(-78.23)"
					fill="#fff"
				/>
				<path
					d="M183.5,389.45a38.07,38.07,0,1,1,38.06-38.06A38.1,38.1,0,0,1,183.5,389.45Zm0-73.63a35.57,35.57,0,1,0,35.56,35.57A35.61,35.61,0,0,0,183.5,315.82Z"
					transform="translate(318.33 355.79)"
				/>
				<rect
					x="41.02"
					y="387.25"
					width="71.72"
					height="71.72"
					transform="translate(-27.89 855.78) rotate(-90)"
					fill="#fff"
				/>
				<rect
					x="41.02"
					y="315.53"
					width="71.72"
					height="71.72"
					transform="translate(43.83 784.06) rotate(-90)"
					fill="#fff"
				/>
				<rect
					x="41.02"
					y="243.81"
					width="71.72"
					height="71.72"
					transform="translate(115.54 712.34) rotate(-90)"
					fill="#fff"
				/>
				<path
					d="M112.74,460.21H41L39.77,459V387.25L41,386h71.72l1.25,1.25V459Zm-70.47-2.5h69.22V388.5H42.27Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M112.74,388.5H41l-1.25-1.25V315.53L41,314.28h71.72l1.25,1.25v71.72ZM42.27,386h69.22V316.78H42.27Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M112.74,316.78H41l-1.25-1.25V243.81L41,242.56h71.72l1.25,1.25v71.72Zm-70.47-2.5h69.22V245.06H42.27Z"
					transform="translate(318.33 355.79)"
				/>
				<rect
					x="57.76"
					y="423.1"
					width="19.12"
					height="90.84"
					transform="translate(-82.87 891.63) rotate(-90)"
					fill="#fff"
				/>
				<rect
					x="57.76"
					y="188.83"
					width="19.12"
					height="90.84"
					transform="translate(151.4 657.36) rotate(-90)"
					fill="#fff"
				/>
				<rect
					x="21.9"
					y="243.81"
					width="19.12"
					height="215.15"
					transform="translate(381.26 1058.57) rotate(180)"
					fill="#fff"
				/>
				<path
					d="M112.74,479.34H21.9l-1.25-1.25V459l1.25-1.25h90.84L114,459v19.13Zm-89.59-2.5h88.34V460.21H23.15Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M112.74,245.06H21.9l-1.25-1.25V224.69l1.25-1.25h90.84l1.25,1.25v19.12Zm-89.59-2.5h88.34V225.94H23.15Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M41,460.21H21.9L20.65,459V243.81l1.25-1.25H41l1.25,1.25V459Zm-17.87-2.5H39.77V245.06H23.15Z"
					transform="translate(318.33 355.79)"
				/>
				<rect
					x="370.6"
					y="599.6"
					width="2.5"
					height="215.15"
				/>
				<rect
					x="340.23"
					y="609.87"
					width="90.84"
					height="2.5"
				/>
				<rect
					x="340.23"
					y="801.99"
					width="90.84"
					height="2.5"
				/>
			</g>
			<g id="Gas">
				<circle
					cx="391.08"
					cy="429.62"
					r="28.9"
					fill="#fff"
				/>
				<path
					d="M72.75,104a30.15,30.15,0,1,1,30.14-30.14A30.18,30.18,0,0,1,72.75,104Zm0-57.79a27.65,27.65,0,1,0,27.64,27.65A27.68,27.68,0,0,0,72.75,46.18Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M72.75,104a30.15,30.15,0,1,1,30.14-30.14A30.18,30.18,0,0,1,72.75,104Zm0-57.79a27.65,27.65,0,1,0,27.64,27.65A27.68,27.68,0,0,0,72.75,46.18Z"
					transform="translate(318.33 355.79)"
				/>
				<circle
					cx="152.45"
					cy="73.83"
					r="28.9"
					transform="translate(301.76 418.53) rotate(-22.09)"
					fill="#fff"
				/>
				<path
					d="M152.45,104A30.15,30.15,0,1,1,182.6,73.83,30.18,30.18,0,0,1,152.45,104Zm0-57.79A27.65,27.65,0,1,0,180.1,73.83,27.68,27.68,0,0,0,152.45,46.18Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M152.45,104A30.15,30.15,0,1,1,182.6,73.83,30.18,30.18,0,0,1,152.45,104Zm0-57.79A27.65,27.65,0,1,0,180.1,73.83,27.68,27.68,0,0,0,152.45,46.18Z"
					transform="translate(318.33 355.79)"
				/>
				<circle
					cx="391.08"
					cy="529.54"
					r="28.9"
					fill="#fff"
				/>
				<path
					d="M72.75,203.9a30.15,30.15,0,1,1,30.14-30.15A30.18,30.18,0,0,1,72.75,203.9Zm0-57.8a27.65,27.65,0,1,0,27.64,27.65A27.68,27.68,0,0,0,72.75,146.1Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M72.75,203.9a30.15,30.15,0,1,1,30.14-30.15A30.18,30.18,0,0,1,72.75,203.9Zm0-57.8a27.65,27.65,0,1,0,27.64,27.65A27.68,27.68,0,0,0,72.75,146.1Z"
					transform="translate(318.33 355.79)"
				/>
				<circle
					cx="152.45"
					cy="173.75"
					r="28.9"
					transform="translate(264.19 425.87) rotate(-22.09)"
					fill="#fff"
				/>
				<path
					d="M152.45,203.9a30.15,30.15,0,1,1,30.15-30.15A30.19,30.19,0,0,1,152.45,203.9Zm0-57.8a27.65,27.65,0,1,0,27.65,27.65A27.68,27.68,0,0,0,152.45,146.1Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M152.45,203.9a30.15,30.15,0,1,1,30.15-30.15A30.19,30.19,0,0,1,152.45,203.9Zm0-57.8a27.65,27.65,0,1,0,27.65,27.65A27.68,27.68,0,0,0,152.45,146.1Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M72.75,94.34A20.51,20.51,0,1,1,93.26,73.83,20.53,20.53,0,0,1,72.75,94.34Zm0-38.52a18,18,0,1,0,18,18A18,18,0,0,0,72.75,55.82Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M152.45,94.34A20.51,20.51,0,1,1,173,73.83,20.53,20.53,0,0,1,152.45,94.34Zm0-38.52a18,18,0,1,0,18,18A18,18,0,0,0,152.45,55.82Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M152.45,194.26A20.51,20.51,0,1,1,173,173.75,20.53,20.53,0,0,1,152.45,194.26Zm0-38.52a18,18,0,1,0,18,18A18,18,0,0,0,152.45,155.74Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M72.75,194.26a20.51,20.51,0,1,1,20.51-20.51A20.53,20.53,0,0,1,72.75,194.26Zm0-38.52a18,18,0,1,0,18,18A18,18,0,0,0,72.75,155.74Z"
					transform="translate(318.33 355.79)"
				/>
				<rect
					x="340.23"
					y="429.62"
					width="181.4"
					height="99.92"
					fill="#fff"
				/>
				<path
					d="M203.3,175H21.9l-1.25-1.25V73.83l1.25-1.25H203.3l1.25,1.25v99.92ZM23.15,172.5h178.9V75.08H23.15Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M203.3,175H21.9l-1.25-1.25V73.83l1.25-1.25H203.3l1.25,1.25v99.92ZM23.15,172.5h178.9V75.08H23.15Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M102.82,112.25H42.67L41.42,111V81.78l1.25-1.25h60.15l1.25,1.25V111Zm-58.9-2.5h57.65V83H43.92Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M72.75,109.89a13.5,13.5,0,1,1,13.49-13.5A13.52,13.52,0,0,1,72.75,109.89Zm0-24.49a11,11,0,1,0,11,11A11,11,0,0,0,72.75,85.4Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M72.75,105.06a8.67,8.67,0,1,1,8.67-8.67A8.68,8.68,0,0,1,72.75,105.06Zm0-14.84a6.17,6.17,0,1,0,6.17,6.17A6.18,6.18,0,0,0,72.75,90.22Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M102.82,167.79H42.67l-1.25-1.25V137.32l1.25-1.25h60.15l1.25,1.25v29.22Zm-58.9-2.5h57.65V138.57H43.92Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M72.75,165.43a13.5,13.5,0,1,1,13.49-13.5A13.52,13.52,0,0,1,72.75,165.43Zm0-24.49a11,11,0,1,0,11,11A11,11,0,0,0,72.75,140.94Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M72.75,160.6a8.67,8.67,0,1,1,8.67-8.67A8.68,8.68,0,0,1,72.75,160.6Zm0-14.84a6.17,6.17,0,1,0,6.17,6.17A6.18,6.18,0,0,0,72.75,145.76Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M182.52,112.25H122.37L121.12,111V81.78l1.25-1.25h60.15l1.25,1.25V111Zm-58.9-2.5h57.65V83H123.62Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M152.45,109.89a13.5,13.5,0,1,1,13.49-13.5A13.52,13.52,0,0,1,152.45,109.89Zm0-24.49a11,11,0,1,0,11,11A11,11,0,0,0,152.45,85.4Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M152.45,105.06a8.67,8.67,0,1,1,8.67-8.67A8.68,8.68,0,0,1,152.45,105.06Zm0-14.84a6.17,6.17,0,1,0,6.17,6.17A6.18,6.18,0,0,0,152.45,90.22Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M182.52,167.79H122.37l-1.25-1.25V137.32l1.25-1.25h60.15l1.25,1.25v29.22Zm-58.9-2.5h57.65V138.57H123.62Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M152.45,165.43a13.5,13.5,0,1,1,13.49-13.5A13.52,13.52,0,0,1,152.45,165.43Zm0-24.49a11,11,0,1,0,11,11A11,11,0,0,0,152.45,140.94Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M152.45,160.6a8.67,8.67,0,1,1,8.67-8.67A8.68,8.68,0,0,1,152.45,160.6Zm0-14.84a6.17,6.17,0,1,0,6.17,6.17A6.18,6.18,0,0,0,152.45,145.76Z"
					transform="translate(318.33 355.79)"
				/>
			</g>
			<g id="Keuken">
				<rect
					x="564.92"
					y="374.57"
					width="286.15"
					height="63.83"
					fill="#fff"
				/>
				<path
					d="M532.73,83.87H246.58l-1.25-1.25V18.78l1.25-1.25H532.73L534,18.78V82.62Zm-284.9-2.5H531.48V20H247.83Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M532.73,83.87H246.58l-1.25-1.25V18.78l1.25-1.25H532.73L534,18.78V82.62Zm-284.9-2.5H531.48V20H247.83Z"
					transform="translate(318.33 355.79)"
				/>
				<rect
					x="577.71"
					y="395.5"
					width="59.63"
					height="31.29"
					fill="#fff"
				/>
				<path
					d="M319,72.25H259.38L258.13,71V39.71l1.25-1.25H319l1.25,1.25V71Zm-58.38-2.5h57.13V41H260.63Z"
					transform="translate(318.33 355.79)"
				/>
				<rect
					x="648.36"
					y="395.5"
					width="59.63"
					height="31.29"
					fill="#fff"
				/>
				<path
					d="M389.66,72.25H330L328.78,71V39.71L330,38.46h59.63l1.25,1.25V71Zm-58.38-2.5h57.13V41H331.28Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M290.67,54.18a4.14,4.14,0,1,1-4.14-4.13A4.13,4.13,0,0,1,290.67,54.18Z"
					transform="translate(318.33 355.79)"
					fill="#fff"
				/>
				<path
					d="M286.53,59.56a5.38,5.38,0,1,1,5.39-5.38A5.39,5.39,0,0,1,286.53,59.56Zm0-8.26a2.88,2.88,0,1,0,2.89,2.88A2.88,2.88,0,0,0,286.53,51.3Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M364,55.85a4.13,4.13,0,1,1-4.13-4.13A4.13,4.13,0,0,1,364,55.85Z"
					transform="translate(318.33 355.79)"
					fill="#fff"
				/>
				<path
					d="M359.84,61.23a5.38,5.38,0,1,1,5.38-5.38A5.38,5.38,0,0,1,359.84,61.23Zm0-8.26a2.88,2.88,0,1,0,2.88,2.88A2.88,2.88,0,0,0,359.84,53Z"
					transform="translate(318.33 355.79)"
				/>
				<rect
					x="675.37"
					y="374.57"
					width="5.61"
					height="27.13"
					fill="#fff"
				/>
				<path
					d="M362.65,47.16H357l-1.25-1.25V18.78L357,17.53h5.61l1.25,1.25V45.91Zm-4.36-2.5h3.11V20h-3.11Z"
					transform="translate(318.33 355.79)"
				/>
				<rect
					x="751.58"
					y="387.04"
					width="88.85"
					height="40.64"
					fill="#fff"
				/>
				<path
					d="M523.27,73.06H432.08v-43h91.19Zm-88.85-2.34h86.51V32.42H434.42Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M454.29,60A5.32,5.32,0,1,1,449,54.67,5.32,5.32,0,0,1,454.29,60Z"
					transform="translate(318.33 355.79)"
					fill="#fff"
				/>
				<path
					d="M449,66.55A6.57,6.57,0,1,1,455.54,60,6.57,6.57,0,0,1,449,66.55Zm0-10.63A4.07,4.07,0,1,0,453,60,4.06,4.06,0,0,0,449,55.92Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M482,60a5.32,5.32,0,1,1-5.32-5.31A5.32,5.32,0,0,1,482,60Z"
					transform="translate(318.33 355.79)"
					fill="#fff"
				/>
				<path
					d="M476.64,66.55A6.57,6.57,0,1,1,483.21,60,6.58,6.58,0,0,1,476.64,66.55Zm0-10.63A4.07,4.07,0,1,0,480.71,60,4.07,4.07,0,0,0,476.64,55.92Z"
					transform="translate(318.33 355.79)"
				/>
				<circle
					cx="822.64"
					cy="415.77"
					r="5.32"
					fill="#fff"
				/>
				<path
					d="M504.31,66.55A6.57,6.57,0,1,1,510.88,60,6.58,6.58,0,0,1,504.31,66.55Zm0-10.63A4.07,4.07,0,1,0,508.38,60,4.07,4.07,0,0,0,504.31,55.92Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M454.29,43.39A5.32,5.32,0,1,1,449,38.07,5.31,5.31,0,0,1,454.29,43.39Z"
					transform="translate(318.33 355.79)"
					fill="#fff"
				/>
				<path
					d="M449,50a6.57,6.57,0,1,1,6.57-6.56A6.56,6.56,0,0,1,449,50Zm0-10.63A4.07,4.07,0,1,0,453,43.39,4.07,4.07,0,0,0,449,39.32Z"
					transform="translate(318.33 355.79)"
				/>
				<path
					d="M482,43.39a5.32,5.32,0,1,1-5.32-5.32A5.32,5.32,0,0,1,482,43.39Z"
					transform="translate(318.33 355.79)"
					fill="#fff"
				/>
				<path
					d="M476.64,50a6.57,6.57,0,1,1,6.57-6.56A6.57,6.57,0,0,1,476.64,50Zm0-10.63a4.07,4.07,0,1,0,4.07,4.07A4.07,4.07,0,0,0,476.64,39.32Z"
					transform="translate(318.33 355.79)"
				/>
				<circle
					cx="822.64"
					cy="399.18"
					r="5.32"
					fill="#fff"
				/>
				<path
					d="M504.31,50a6.57,6.57,0,1,1,6.57-6.56A6.57,6.57,0,0,1,504.31,50Zm0-10.63a4.07,4.07,0,1,0,4.07,4.07A4.07,4.07,0,0,0,504.31,39.32Z"
					transform="translate(318.33 355.79)"
				/>
				<rect
					x="751.58"
					y="397.93"
					width="88.85"
					height="2.5"
				/>
				<rect
					x="752.17"
					y="414.52"
					width="88.26"
					height="2.5"
				/>
				<rect
					x="851.06"
					y="374.57"
					width="100.37"
					height="89.02"
					fill="#fff"
				/>
				<path
					d="M633.1,109.06H532.73l-1.25-1.25v-89l1.25-1.25H633.1l1.25,1.25v89ZM534,106.56h97.87V20H534Z"
					transform="translate(318.33 355.79)"
				/>
			</g>
			<g id="Deur3">
				<rect
					x="948.54"
					y="495.79"
					width="19.8"
					height="62.42"
					fill="#fff"
				/>
				<path
					d="M640.16,204.7A63.75,63.75,0,0,1,576.48,141H579a61.24,61.24,0,0,0,61.18,61.17Z"
					transform="translate(318.33 355.79)"
				/>
			</g>
			<g id="Deur2">
				<rect
					x="769.55"
					y="887.7"
					width="19.8"
					height="62.42"
					fill="#fff"
				/>
				<path
					d="M461.17,596.62a63.76,63.76,0,0,1-63.68-63.68H400a61.26,61.26,0,0,0,61.18,61.18Z"
					transform="translate(318.33 355.79)"
				/>
			</g>
			<g id="Deur1">
				<rect
					x="113.72"
					y="544.28"
					width="19.8"
					height="62.42"
					transform="translate(-146.62 923.86) rotate(-77.15)"
					fill="#fff"
				/>
				<path
					d="M93.39,568.64,91,568.09a63.68,63.68,0,0,1,76.24-47.93l-.56,2.44a61.16,61.16,0,0,0-73.24,46Z"
					transform="translate(318.33 355.79)"
				/>
			</g>
		</svg> -->
		<p class="lead">
			<span class="blur">
				Omi Jan Bouw helpt u graag bij het uitdenken en realiseren van een
				exclusieve verbouwing. <br />
				In gezamenlijk overleg worden uw wensen en eisen vertaald naar een
				deskundig ontwerp met de bijbehorende materialisatie.
			</span>
		</p>

		<br />
	</div>

	<router-link
		to="#over"
		class="pijl z-1"
	>
		<img
			src="@/assets/ikon/arrow-down-solid.svg"
			alt="Arrow"
			class="ikon text-derde"
		/>
	</router-link>
</template>

<script>
	export default {
		name: "Show",
		created() {},
		data() {
			return {};
		},
		props: {},
		methods: {},
	};
</script>

<style
	lang="scss"
	scoped
>


 

	.pijl {
		display: flex;
		position: absolute;
		justify-content: center;
		bottom: 1.5rem;
		background-color: transparent;

		.ikon {
			z-index: 999;
			width: 20%;
			height: auto;
			display: flex;
			color: $derde;
			animation: pijlAni 1.3s linear infinite;
		}
	}

	.lead {
		max-width: 350px;
		background-color: lighten($primary, 58%);
		border-radius: 100px;

		@include labM {
			max-width: 600px;
		}
	}

	.blur {
		padding: 5px 10px;
		color: $dark;
		border-radius: 1rem;
		font-size: $m-font;
		font-weight: bold;
		width: 100%;
	}

	#OBJECTS {
		width: 100%;
		height: auto;
	}

	#Deur3 path {
		color: red;
	}
	#Deur3 path:hover {
		transform: translateY(20px);
		transition: translate 0.6s ease-in-out;
	}

	@keyframes pijlAni {
		from {
			opacity: 0;
			transform: translate(0);
		}
		to {
			opacity: 1;
			transform: translateY(30px);
		}
	}
</style>
