// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getFirestore } from "firebase/firestore";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCXfjzRKizD2w3ZiAXRvXs9lcd3FrIAR9E',
  authDomain: 'armando-d178d.firebaseapp.com',
  projectId: 'armando-d178d',
  storageBucket: 'armando-d178d.appspot.com',
  messagingSenderId: '893484657744',
  appId: '1:893484657744:web:c93c24e8b30750b99b7555',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

export const db = getFirestore(app)