<template>
  <article class="contact">
    <h1>Contact</h1>

  
  </article>
</template>

<script>
export default {
  name: 'ContactView',
  components: {},
}
</script>
